import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SectionService } from 'src/app/services/section.service';
import {
  LoadSectionAction,
  LoadSectionFailureAction,
  LoadSectionSuccessAction,
  SectionActionTypes
} from '../actions/section.actions';

@Injectable()
export class SectionEffects {

  constructor(
    private actions$: Actions,
    private sectionService: SectionService,
  ) { }

  loadSection$ = createEffect(() => this.actions$.pipe(
      ofType<LoadSectionAction>(SectionActionTypes.LOAD_SECTION),
      mergeMap((data) => this.sectionService.getSectionBySlug(data.slug)
        .pipe(
          map(section => {
            return new LoadSectionSuccessAction(section);
          }),
          catchError(error => of(new LoadSectionFailureAction(error)))
        )
      )
    )
  );

}
