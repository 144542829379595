import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Slider } from 'src/app/models/slider';
import { SliderAction, SliderActionTypes } from '../actions/slider.actions';

export interface SliderState {
  sliders: Slider[],
  sliders_loading: boolean,
  error: Error
};

const initialState: SliderState = {
  sliders: [] = [],
  sliders_loading: false,
  error: undefined
};

export function SliderReducer(state: SliderState = initialState, action: SliderAction) {
  switch (action.type) {
    case SliderActionTypes.LOAD_SLIDERS:
      return {
        ...state,
        sliders_loading: true
      };
    case SliderActionTypes.LOAD_SLIDERS_SUCCESS:
      return {
        ...state,
        sliders: action.payload,
        sliders_loading: false
      };
    case SliderActionTypes.LOAD_SLIDERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        sliders_loading: false
      };
    default:
      return state;
  }
}
