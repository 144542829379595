<div class="widget bg-light mb-4" *ngIf="!(loading$ | async)">
    <h5 class="mb-4">Recent News</h5>

    <div class="recent-post d-flex" *ngFor="let item of recent_news$ | async">

        <img *ngIf="item.image_url !== null" [src]="item.image_url + '?width=136&height=136'" alt="image">

        <div class="text alt-font">
            <a [routerLink]="[ '/news/' + item.slug ]" [title]="item.title">{{ item.title | slice:0:120 }}...</a>
        </div>

    </div>

</div>