import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { News } from 'src/app/models/news';
import { LoadRecentNewsAction } from 'src/app/store/actions/news.actions';
import { AppState } from 'src/app/store/models/app-state.model';

@Component({
  selector: 'app-recent-news',
  templateUrl: './recent-news.component.html',
  styleUrls: ['./recent-news.component.scss']
})
export class RecentNewsComponent implements OnInit {

  recent_news$: Observable<News[]> = this.store.select(state => state.news.recent_news);
  loading$: Observable<boolean> = this.store.select(state => state.news.recent_news_loading);
  error$: Observable<Error> = this.store.select(state => state.news.recent_news_error);

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.getRecentNews();
  }

  getRecentNews() {
    this.store.dispatch(new LoadRecentNewsAction());
  }
}
