import { Action } from '@ngrx/store';
import { Section } from 'src/app/models/section';

export enum SectionActionTypes {
  LOAD_SECTION = '[SECTION] Load Section',
  LOAD_SECTION_SUCCESS = '[SECTION] Load Section Success',
  LOAD_SECTION_FAILURE = '[SECTION] Load Section Failure',
}

export class LoadSectionAction implements Action {
  readonly type = SectionActionTypes.LOAD_SECTION;

  constructor(public slug: string) {}
}

export class LoadSectionSuccessAction implements Action {
  readonly type = SectionActionTypes.LOAD_SECTION_SUCCESS;

  constructor(public payload: Section) {}
}

export class LoadSectionFailureAction implements Action {
  readonly type = SectionActionTypes.LOAD_SECTION_FAILURE;

  constructor(public payload: Error) {}
}

export type SectionAction = LoadSectionAction |
LoadSectionSuccessAction |
LoadSectionFailureAction;
