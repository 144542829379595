<section id="home" class="cursor-light gradient-duetron-bg p-0">
    <h2 class="d-none">hidden</h2>
    <div id="rev_slider_19_1_wrapper" class="rev_slider_wrapper fullscreen-container" data-alias="wexim_slider_01" data-source="gallery" style="background:transparent;padding:0px;">
        <!-- START REVOLUTION SLIDER 5.4.8.1 fullscreen mode -->
        <div id="rev_slider_19_1" class="rev_slider fullscreenbanner" data-version="5.4.8.1" #rev_slider>
            <ul *ngIf="!(loading$ | async)">
                <li *ngFor="let item of sliders$ | async; let i = index" [attr.data-index]="'rs-' + (i+1)" data-transition="crossfade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default"
                    data-thumb="images/slide-img1.jpg" data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10=""
                    data-description="">

                    <ng-container *ngIf="item.slider_type === 1">

                        <!-- MAIN IMAGE -->
                        <img [src]="item?.image_url" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg" data-no-retina>
                        <!-- LAYERS -->

                        <!-- Overlay -->
                        <div class="gradient-duetron-bg bg-overlay opacity-8 z-index-1"></div>

                        <!-- LAYER NR. 1 -->
                        <!-- <div class="tp-caption   tp-resizeme" id="slide-91-layer-1" data-x="['left','left','center','center']" data-hoffset="['0','50','0','0']" data-y="['top','top','middle','middle']" data-voffset="['309','262','-130','-130']" data-fontsize="['16','16','16','16']"
                            data-width="none" data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"delay":200,"speed":900,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 5; white-space: nowrap; font-size: 16px; line-height: 20px; font-weight: 300; color: #ffffff; letter-spacing: 0px;font-family:Roboto;">
                            <p class="text-white" *ngIf="item.sub_title !== null">{{ item.sub_title }}</p>
                        </div> -->

                        <!-- LAYER NR. 2 -->
                        <div class="tp-caption   tp-resizeme" id="slide-91-layer-2" data-x="['left','left','center','center']" data-hoffset="['0','50','0','0']" data-y="['top','top','top','top']" data-voffset="['357','309','383','255']" data-fontsize="['40','40','40','35']"
                            data-width="none" data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"delay":720,"split":"chars","splitdelay":0.1,"speed":900,"split_direction":"forward","frame":"0","from":"sX:0.8;sY:0.8;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 6; white-space: nowrap; font-size: 40px; line-height: 50px; font-weight: 500; color: #ffffff; letter-spacing: 0px;font-family:Poppins;">
                            {{ item.title }}
                        </div>

                        <!-- LAYER NR. 3 -->
                        <div class="tp-caption   tp-resizeme" id="slide-91-layer-3" data-x="['left','left','center','center']" data-hoffset="['0','50','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['0','0','-15','-30']" data-fontsize="['40','40','40','35']"
                            data-width="none" data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"delay":1290,"speed":900,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 7; white-space: nowrap; font-size: 40px; line-height: 50px; font-weight: 200; color: #ffffff; letter-spacing: 0px;font-family:Poppins;">{{ item.sub_title }}</div>

                        <!-- LAYER NR. 4 -->
                        <div class="tp-caption   tp-resizeme" id="slide-91-layer-4" data-x="['left','left','center','center']" data-hoffset="['0','50','1','-1']" data-y="['top','top','middle','middle']" data-voffset="['484','447','60','45']" data-fontsize="['16','16','16','16']"
                            data-lineheight="['22','22','22','18']" data-width="['601','530','700','450']" data-height="none" data-whitespace="normal" data-type="text" data-responsive_offset="on" data-frames='[{"delay":1680,"speed":900,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 8; min-width: 601px; max-width: 601px; white-space: normal; font-size: 16px; line-height: 22px; font-weight: 300; color: #ffffff; letter-spacing: 0px;font-family:Roboto;">
                            <p class="text-white" *ngIf="item.description !== null">{{ item.description }}</p>
                        </div>

                        <!-- LAYER NR. 12 -->
                        <div class="tp-caption   tp-resizeme" data-x="['left','left','center','center']" data-hoffset="['0','50','0','0']" data-y="['top','top','middle','middle']" data-voffset="['586','560','164','150']" data-width="['601','530','601','450']" data-height="none"
                            data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"delay":2150,"speed":900,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power2.easeOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 9; letter-spacing: .5px;">
                            <a *ngIf="item.button_text !== null && item.button_url !== null" class="btn btn-large btn-rounded btn-white" [routerLink]="[ item.button_url ]" [title]="item.button_text">{{ item.button_text }}</a>
                        </div>

                        <!-- LAYER NR. 6 -->
                        <div class="tp-caption d-none d-lg-block tp-resizeme rs-parallaxlevel-1" id="slide-91-layer-7" data-x="['right','right','right','right']" data-hoffset="['0','-50','-1200','-1200']" data-y="['middle','middle','middle','middle']" data-voffset="['0','0','-9','-27']"
                            data-width="none" data-height="none" data-whitespace="normal" data-type="image" data-responsive_offset="on" data-frames='[{"delay":1810,"speed":900,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 10;">
                            <div class="rs-looped rs-wave" data-speed="10" data-angle="0" data-radius="5px" data-origin="50% 50%"><img src="assets/images/object1.png" alt="" data-ww="['576px','530px','462px','462px']" data-hh="['566px','530px','454px','454px']" data-no-retina> </div>
                        </div>

                        <!-- LAYER NR. 7 -->
                        <div class="tp-caption d-none d-lg-block tp-resizeme rs-parallaxlevel-2" id="slide-91-layer-8" data-x="['right','right','right','right']" data-hoffset="['10','-60','-1200','-1200']" data-y="['middle','middle','middle','middle']" data-voffset="['70','70','20','2']"
                            data-width="none" data-height="none" data-whitespace="normal" data-type="image" data-responsive_offset="on" data-frames='[{"delay":2330,"speed":900,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 11;">

                            <div class="rs-looped rs-wave" data-speed="20" data-angle="0" data-radius="8px" data-origin="50% 50%">
                                <svg width="500" height="495">
                                    <g>
                                        <clipPath [id]="'clipPolygon' + item.id">
                                            <polygon points="186 13,164 17,150 19,118 27,92 36,78 44,59 57,39 78,26 98,18 118,14 133,12 143,9 159,9 174,12 191,15 207,18 219,22 231,28 244,38 267,50 290,65 313,82 337,97 356,109 369,126 385,138 397,156 411,176 422,192 427,211 431,226 433,244 431,258 428,272 423,286 417,298 409,315 397,336 374,349 357,363 335,380 306,394 277,405 250,414 224,423 196,427 178,429 162,429 140,426 122,420 100,413 86,404 72,391 57,373 42,355 32,333 24,307 18,278 13,249 11,207 11,182 14">
                                            </polygon>
                                        </clipPath>
                                    </g>
                                    <image [attr.clip-path]="'url(#clipPolygon' + item.id + ')'" height="100%" width="100%" [attr.xlink:href]="item.small_image_url" />
                                </svg>
                            </div>

                        </div>
                    </ng-container>

                    <ng-container *ngIf="item.slider_type === 2">
                        <!-- MAIN IMAGE -->
                        <img [src]="item?.image_url" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off" class="rev-slidebg" data-no-retina>
                        <!-- LAYERS -->

                        <!-- Overlay -->
                        <div class="gradient-duetron-bg bg-overlay opacity-8 z-index-1"></div>

                        <!-- LAYER NR. 8 -->
                        <div class="tp-caption   tp-resizeme" id="slide-92-layer-1" data-x="['center','center','center','center']" data-hoffset="['-5','-5','-5','-5']" data-y="['middle','middle','middle','middle']" data-voffset="['-77','-77','-77','-85']" data-fontsize="['30','30','25','20']"
                            data-fontweight="['600','600','600','500']" data-letterspacing="['4','0','0','2']" data-width="none" data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"delay":240,"speed":900,"frame":"0","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 5; white-space: nowrap; font-size: 30px; line-height: 35px; font-weight: 600; color: #ffffff; letter-spacing: 0px;font-family:Poppins;">
                            {{ item.sub_title }}
                        </div>

                        <!-- LAYER NR. 9 -->
                        <div class="tp-caption   tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','3']" data-y="['middle','middle','middle','middle']" data-voffset="['0','7','7','-32']" data-fontsize="['80','70','60','40']" data-width="none"
                            data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"delay":950,"speed":2000,"sfxcolor":"#ffffff","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 6; white-space: nowrap; font-size: 70px; line-height: 80px; font-weight: 600; color: #ffffff; letter-spacing: 4px;font-family:Poppins;">
                            {{ item.title }}
                        </div>

                        <!-- LAYER NR. 10 -->
                        <div class="tp-caption   tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['1','1','0','4']" data-y="['middle','middle','middle','middle']" data-voffset="['83','83','83','18']" data-fontsize="['18','18','16','14']" data-width="500"
                            data-height="none" data-whitespace="normal" data-type="text" data-responsive_offset="on" data-frames='[{"delay":1670,"speed":900,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 7; min-width: 500px; max-width: 500px; white-space: normal; font-size: 18px; line-height: 22px; font-weight: 300; color: #ffffff; letter-spacing: 1px;font-family:Roboto;">
                            <p class="text-white" *ngIf="item.description !== null">{{ item.description }} </p>
                        </div>

                        <!-- LAYER NR. 11 -->
                        <div class="tp-caption   tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['170','170','170','95']" data-fontsize="['20','20','16','16']" data-width="['601','530','601','450']"
                            data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"delay":2300,"speed":900,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 9; letter-spacing: .5px;">

                            <a *ngIf="item.button_text !== null && item.button_url !== null" class="btn btn-large btn-rounded btn-transparent-white" [routerLink]="[ item.button_url ]" [title]="item.button_text">{{ item.button_text }}</a>

                        </div>
                    </ng-container>

                    <ng-container *ngIf="item.slider_type === 3">
                        <!-- MAIN IMAGE -->
                        <img [src]="item?.image_url" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off" class="rev-slidebg" data-no-retina>
                        <!-- LAYERS -->

                        <!-- Overlay -->
                        <div class="gradient-duetron-bg bg-overlay opacity-8 z-index-1"></div>

                        <div class="rs-background-video-layer" data-forcerewind="on" data-volume="mute" data-videowidth="100%" data-videoheight="100vh" [attr.data-videomp4]="item.video_url" data-videopreload="auto" data-videoloop="loopandnoslidestop" data-forceCover="1" data-aspectratio="16:9"
                            data-autoplay="true" data-autoplayonlyfirsttime="false"></div>


                        <!-- LAYER NR. 1 -->
                        <!-- <div class="tp-caption   tp-resizeme" data-x="['right','right','center','center']" data-hoffset="['0','50','0','0']" data-y="['top','top','middle','middle']" data-voffset="['309','262','-130','-130']" data-fontsize="['16','16','16','16']" data-width="none"
                            data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"delay":200,"speed":900,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['right','right','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 5; white-space: nowrap; font-size: 16px; line-height: 20px; font-weight: 300; color: #ffffff; letter-spacing: 0px;font-family:Roboto;">
                            <p class="text-white" *ngIf="item.sub_title !== null">{{ item.sub_title }}</p>
                        </div> -->

                        <!-- LAYER NR. 2 -->
                        <div class="tp-caption   tp-resizeme" data-x="['right','right','center','center']" data-hoffset="['0','50','0','0']" data-y="['top','top','top','top']" data-voffset="['357','309','383','255']" data-fontsize="['40','40','40','35']" data-width="none" data-height="none"
                            data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"delay":720,"split":"chars","splitdelay":0.1,"speed":900,"split_direction":"forward","frame":"0","from":"sX:0.8;sY:0.8;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['right','right','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 6; white-space: nowrap; font-size: 40px; line-height: 50px; font-weight: 500; color: #ffffff; letter-spacing: 0px;font-family:Poppins;">
                            {{ item.title }}
                        </div>

                        <!-- LAYER NR. 3 -->
                        <div class="tp-caption   tp-resizeme" data-x="['right','right','center','center']" data-hoffset="['0','50','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['0','0','-15','-30']" data-fontsize="['40','40','40','35']" data-width="none"
                            data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"delay":1290,"speed":900,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['right','right','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 7; white-space: nowrap; font-size: 40px; line-height: 50px; font-weight: 200; color: #ffffff; letter-spacing: 0px;font-family:Poppins;">
                            {{ item.sub_title }}
                        </div>

                        <!-- LAYER NR. 4 -->
                        <div class="tp-caption   tp-resizeme" data-x="['right','right','center','center']" data-hoffset="['0','50','1','-1']" data-y="['top','top','middle','middle']" data-voffset="['484','447','60','45']" data-fontsize="['16','16','16','16']" data-lineheight="['22','22','22','18']"
                            data-width="['601','530','700','450']" data-height="none" data-whitespace="normal" data-type="text" data-responsive_offset="on" data-frames='[{"delay":1680,"speed":900,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['right','right','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 8; min-width: 601px; max-width: 601px; white-space: normal; font-size: 16px; line-height: 22px; font-weight: 300; color: #ffffff; letter-spacing: 0px;font-family:Roboto;">
                            <p class="text-white" *ngIf="item.description !== null">{{ item.description }}</p>
                        </div>

                        <!-- LAYER NR. 12 -->
                        <div class="tp-caption   tp-resizeme" data-x="['right','right','center','center']" data-hoffset="['0','50','0','0']" data-y="['top','top','middle','middle']" data-voffset="['586','560','164','145']" data-width="['601','530','601','450']" data-height="none"
                            data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"delay":2150,"speed":900,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power2.easeOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['right','right','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 9; letter-spacing: .5px;">
                            <a *ngIf="item.button_text !== null && item.button_url !== null" class="btn btn-large btn-rounded btn-white" [routerLink]="[ item.button_url ]" [title]="item.button_text">
                              {{ item.button_text }}
                            </a>
                        </div>
                    </ng-container>
                </li>

            </ul>
        </div>
        <!-- END REVOLUTION SLIDER -->
    </div>

    <app-slider-social></app-slider-social>

    <a href="#about" (click)="gotoSection($event)" class="scroll-down scroll">Our Services <i class="fa fa-long-arrow-down"></i></a>

</section>