import { Section } from 'src/app/models/section';
import { SectionAction, SectionActionTypes } from '../actions/section.actions';

export interface SectionState {
  section: Section,
  section_loading: boolean,
  error: Error
};

const initialState: SectionState = {
  section: undefined,
  section_loading: false,
  error: undefined
};

export function SectionReducer(state: SectionState, action: SectionAction) {
  switch (action.type) {
    case SectionActionTypes.LOAD_SECTION:
      return {
        ...state,
        section_loading: true
      };
    case SectionActionTypes.LOAD_SECTION_SUCCESS:
      return {
        ...state,
        section: action.payload,
        section_loading: false
      };
    case SectionActionTypes.LOAD_SECTION_FAILURE:
      return {
        ...state,
        error: action.payload,
        section_loading: false
      };
    default:
      return state;
  }
}
