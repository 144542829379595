import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HomeComponent } from './components/pages/home/home.component';
import { NewsDetailsComponent } from './components/pages/news/news-details/news-details.component';
import { NewsComponent } from './components/pages/news/news/news.component';
import { PageNotFoundComponent } from './components/pages/page-not-found/page-not-found.component';
import { ProductDetailsComponent } from './components/pages/products/product-details/product-details.component';
import { ReferencesComponent } from './components/pages/references/references.component';
import { PagesComponent } from './components/pages/pages/pages/pages.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'pages/:slug', component: PagesComponent },
  { path: 'news', component: NewsComponent },
  { path: 'news/:slug', component: NewsDetailsComponent },
  { path: 'products/:slug', component: ProductDetailsComponent },
  { path: 'references', component: ReferencesComponent },
  { path: 'contact-us', component: ContactComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
