import { Service } from 'src/app/models/service';
import { ServiceAction, ServiceActionTypes } from '../actions/service.actions';

export interface ServiceState {
  services: Service[],
  services_loading: boolean,
  error: Error
};

const initialState: ServiceState = {
  services: [] = [],
  services_loading: false,
  error: undefined
};

export function ServiceReducer(state: ServiceState = initialState, action: ServiceAction) {
  switch (action.type) {
    case ServiceActionTypes.LOAD_SERVICES:
      return {
        ...state,
        services_loading: true
      };
    case ServiceActionTypes.LOAD_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload,
        services_loading: false
      };
    case ServiceActionTypes.LOAD_SERVICES_FAILURE:
      return {
        ...state,
        error: action.payload,
        services_loading: false
      };
    default:
      return state;
  }
}
