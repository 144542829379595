<section>
    <div class="container">
        <div class="row wow fadeIn">
            <div class="col-md-12 text-center">
                <div class="title d-inline-block">
                    <!-- <h6 class="mb-3 third-color">Have A Question ?</h6> -->
                    <h2 class="gradient-text1 mb-3">Contact Us</h2>
                    <!-- <p>Teknomaks Bilişim Teknolojileri Sanayi ve Dış Tic. Ltd. Şti.</p> -->
                </div>
            </div>
        </div>

        <ng-template ngIf="!(companies_loading$ | async)">
            <div class="row justify-content-center mb-5">
                <div class="col-md-12">
                    <div class="tab-style8 text-center">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" *ngFor="let item of companies$ | async; let i = index">
                                <a [ngClass]="{ 'active' : i === 0 }" class="nav-link" [id]="'home-tab' + item.id" data-toggle="tab" [href]="'#home' + item.id" role="tab" [attr.aria-controls]="'home' + item.id" [attr.aria-selected]="i === 0 ? true : false">
                              {{item.title}}
                            </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row mb-5">
                <div class="col-md-12">
                    <div class="tab-content">
                        <div [ngClass]="{ 'active show' : i === 0 }" *ngFor="let item of companies$ | async; let i = index" class="tab-pane fade" [id]="'home' + item.id" role="tabpanel" [attr.aria-labelledby]="'home-tab' + item.id">

                            <!--App deatil-->
                            <div class="row align-items-center text-center">

                                <div class="col-md-3 wow fadeInUp">
                                    <div class="feature-box">
                                        <span class="item-number gradient-text1">Address</span>
                                        <!-- <h6 class="mb-4">Website Development</h6> -->
                                        <p>{{ item.address }}</p>
                                    </div>
                                </div>

                                <div class="col-md-3 wow fadeInUp">
                                    <div class="feature-box">
                                        <span class="item-number gradient-text1">Phone</span>
                                        <!-- <h6 class="mb-4">Website Development</h6> -->
                                        <p>{{ item.phone }}</p>
                                    </div>
                                </div>

                                <div class="col-md-3 wow fadeInUp">
                                    <div class="feature-box">
                                        <span class="item-number gradient-text1">Email</span>
                                        <!-- <h6 class="mb-4">Website Development</h6> -->
                                        <p>{{ item.email }}</p>
                                    </div>
                                </div>

                                <div class="col-md-3 wow fadeInUp">
                                    <div class="feature-box">
                                        <span class="item-number gradient-text1">Fax</span>
                                        <!-- <h6 class="mb-4">Website Development</h6> -->
                                        <p>{{ item.fax }}</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

    </div>
</section>
