import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { News } from 'src/app/models/news';
import { Section } from 'src/app/models/section';
import { LoadNewsAction } from 'src/app/store/actions/news.actions';
import { LoadSectionAction } from 'src/app/store/actions/section.actions';
import { AppState } from 'src/app/store/models/app-state.model';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss']
})
export class NewsDetailsComponent implements OnInit {

  section$: Observable<Section> = this.store.select(state => state.section.section);
  section_loadig$: Observable<boolean> = this.store.select(state => state.section.section_loading);
  $section_error$: Observable<Error> = this.store.select(state => state.section.error);

  news$: Observable<News> = this.store.select(state => state.news.news);
  loading$: Observable<boolean> = this.store.select(state => state.news.news_loading);
  error$: Observable<Error> = this.store.select(state => state.news.news_error);

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.getSection();
    this.activatedRoute.params.subscribe(routeParams => {
      if (routeParams.slug) {
        this.getNewsBySlug(routeParams.slug);
      }
    });
  }

  getNewsBySlug(slug: string) {
    this.store.dispatch(new LoadNewsAction(slug));
  }

  getSection() {
    this.store.dispatch(new LoadSectionAction('news'));
  }
}
