import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Page } from 'src/app/models/page';
import { LoadPageAction } from 'src/app/store/actions/page.actions';
import { AppState } from 'src/app/store/models/app-state.model';

@Component({
  selector: 'app-home-about-us',
  templateUrl: './home-about-us.component.html',
  styleUrls: ['./home-about-us.component.scss']
})
export class HomeAboutUsComponent implements OnInit {

  page$: Observable<Page> = this.store.select(state => state.page.page);
  loading$: Observable<boolean> = this.store.select(state => state.page.page_loading);
  error$: Observable<Error> = this.store.select(state => state.page.error);

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.getAboutUs();
  }

  getAboutUs() {
    this.store.dispatch(new LoadPageAction("about-us", false));
  }

}
