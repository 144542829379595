import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { MenuService } from 'src/app/services/menu.service';
import {
  LoadHeaderMenusAction,
  LoadHeaderMenusFailureAction,
  LoadHeaderMenusSuccessAction,
  LoadNewsDetailsMenusAction,
  LoadNewsDetailsMenusFailureAction,
  LoadNewsDetailsMenusSuccessAction,
  LoadNewsMenusAction,
  LoadNewsMenusFailureAction,
  LoadNewsMenusSuccessAction,
  LoadSideMenusAction,
  LoadSideMenusFailureAction,
  LoadSideMenusSuccessAction,
  MenuActionTypes
} from '../actions/menu.actions';

@Injectable()
export class MenuEffects {

  constructor(
    private actions$: Actions,
    private menuService: MenuService,
  ) { }

  loadHeaderMenus$ = createEffect(() => this.actions$.pipe(
      ofType<LoadHeaderMenusAction>(MenuActionTypes.LOAD_HEADER_MENUS),
      mergeMap(() => this.menuService.getHeaderMenu()
        .pipe(
          map(headerMenus => { return new LoadHeaderMenusSuccessAction(headerMenus) }),
          catchError(error => of(new LoadHeaderMenusFailureAction(error)))
        )
      )
    )
  );

  loadSideMenus$ = createEffect(() => this.actions$.pipe(
      ofType<LoadSideMenusAction>(MenuActionTypes.LOAD_SIDE_MENUS),
      mergeMap(() => this.menuService.getSideMenu()
        .pipe(
          map(sideMenus => { return new LoadSideMenusSuccessAction(sideMenus) }),
          catchError(error => of(new LoadSideMenusFailureAction(error)))
        )
      )
    )
  );

  loadNewsMenus$ = createEffect(() => this.actions$.pipe(
      ofType<LoadNewsMenusAction>(MenuActionTypes.LOAD_NEWS_MENUS),
      mergeMap(() => this.menuService.getNewsMenu()
        .pipe(
          map(newsMenus => { return new LoadNewsMenusSuccessAction(newsMenus); }),
          catchError(error => of(new LoadNewsMenusFailureAction(error)))
        )
      )
    )
  );

  loadNewsDetailsMenus$ = createEffect(() => this.actions$.pipe(
      ofType<LoadNewsDetailsMenusAction>(MenuActionTypes.LOAD_NEWS_DETAILS_MENUS),
      mergeMap(() => this.menuService.getNewsDetailsMenu()
        .pipe(
          map(newsDetailsMenus => { return new LoadNewsDetailsMenusSuccessAction(newsDetailsMenus) }),
          catchError(error => of(new LoadNewsDetailsMenusFailureAction(error)))
        )
      )
    )
  );

}
