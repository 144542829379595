import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BaseMenu } from 'src/app/models/base-menu';
import { LoadNewsMenusAction } from 'src/app/store/actions/menu.actions';
import { AppState } from 'src/app/store/models/app-state.model';

@Component({
  selector: 'app-news-menu',
  templateUrl: './news-menu.component.html',
  styleUrls: ['./news-menu.component.scss']
})
export class NewsMenuComponent implements OnInit {

  news_menus$: Observable<BaseMenu[]> = this.store.select(state => state.menu.news_menus);
  news_menus_loading$: Observable<boolean> = this.store.select(state => state.menu.news_menus_loading);
  news_menus_error$: Observable<Error> = this.store.select(state => state.menu.news_menus_error);

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.getNewsMenu();
  }

  getNewsMenu() {
    this.store.dispatch(new LoadNewsMenusAction());
  }
}
