import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Product } from 'src/app/models/product';
import { LoadProductAction } from 'src/app/store/actions/product.actions';
import { AppState } from 'src/app/store/models/app-state.model';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  product$: Observable<Product> = this.store.select(state => state.product.product);
  loading$: Observable<boolean> = this.store.select(state => state.product.product_loading);
  error$: Observable<Error> = this.store.select(state => state.product.error);

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(routeParams => {
      if (routeParams.slug) {
        this.getProductBySlug(routeParams.slug);
      }
    });
  }

  getProductBySlug(slug: string) {
    this.store.dispatch(new LoadProductAction(slug));
  }

  print(event) {
    event.preventDefault();
    var el = document.querySelector('#product_details');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
