<section class="pb-0">
    <div class="bg-overlay gradient-duetron-bg opacity-9"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="cover-text mt-5">
                    <h2 class="text-white mb-2">404</h2>
                    <p class="text-white">Page Not Found</p>
                    <div class="page_nav">
                        <a routerLink="/" title="Home">Home</a>
                        <span class="main-color"><i class="fa fa-angle-double-right"></i>404</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="page-content">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="error-box">
                    <div class="error-image">
                        <img src="assets/images/404.png" alt="image">
                    </div>
                    <div class="error-text text-center">
                        <h2>404 ERROR</h2>
                        <h3>SORRY, THE PAGE NOT FOUND</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>