import { Action } from '@ngrx/store';
import { Reference } from 'src/app/models/reference';
import { ReferenceCategory } from 'src/app/models/reference-category';

export enum ReferenceActionTypes {
  LOAD_REFERENCES = '[REFERENCE] Load References',
  LOAD_REFERENCES_SUCCESS = '[REFERENCE] Load References Success',
  LOAD_REFERENCES_FAILURE = '[REFERENCE] Load References Failure',

  LOAD_REFERENCE_CATEGORIES = '[REFERENCECATEGORY] Load Reference Categories',
  LOAD_REFERENCE_CATEGORIES_SUCCESS = '[REFERENCECATEGORY] Load Reference Categories Success',
  LOAD_REFERENCE_CATEGORIES_FAILURE = '[REFERENCECATEGORY] Load Reference Categories Failure',
}

export class LoadReferencesAction implements Action {
  readonly type = ReferenceActionTypes.LOAD_REFERENCES;
}

export class LoadReferencesSuccessAction implements Action {
  readonly type = ReferenceActionTypes.LOAD_REFERENCES_SUCCESS;

  constructor(public payload: Array<Reference>) {}
}

export class LoadReferencesFailureAction implements Action {
  readonly type = ReferenceActionTypes.LOAD_REFERENCES_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadReferenceCategoriesAction implements Action {
  readonly type = ReferenceActionTypes.LOAD_REFERENCE_CATEGORIES;
}

export class LoadReferenceCategoriesSuccessAction implements Action {
  readonly type = ReferenceActionTypes.LOAD_REFERENCE_CATEGORIES_SUCCESS;

  constructor(public payload: Array<ReferenceCategory>) {}
}

export class LoadReferenceCategoriesFailureAction implements Action {
  readonly type = ReferenceActionTypes.LOAD_REFERENCE_CATEGORIES_FAILURE;

  constructor(public payload: Error) {}
}

export type ReferenceAction = LoadReferencesAction |
LoadReferencesSuccessAction |
LoadReferencesFailureAction |
LoadReferenceCategoriesAction |
LoadReferenceCategoriesSuccessAction |
LoadReferenceCategoriesFailureAction;
