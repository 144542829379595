import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CompanyService } from 'src/app/services/company.service';
import {
  LoadCompanyAction,
  LoadCompanyFailureAction,
  LoadCompanySuccessAction,
  CompanyActionTypes,
  LoadCompaniesAction,
  LoadCompaniesFailureAction,
  LoadCompaniesSuccessAction
} from '../actions/company.actions';

@Injectable()
export class CompanyEffects {

  constructor(
    private actions$: Actions,
    private companyService: CompanyService
  ) { }

  loadCompanies$ = createEffect(() => this.actions$.pipe(
      ofType<LoadCompaniesAction>(CompanyActionTypes.LOAD_COMPANIES),
      mergeMap(() => this.companyService.getCompanies()
        .pipe(
          map(companies => { return new LoadCompaniesSuccessAction(companies); }),
          catchError(error => of(new LoadCompaniesFailureAction(error)))
        )
      )
    )
  );

  loadCompany = createEffect(() => this.actions$.pipe(
      ofType<LoadCompanyAction>(CompanyActionTypes.LOAD_COMPANY),
      mergeMap(() => this.companyService.getCompanyInfo()
        .pipe(
          map(company => { return new LoadCompanySuccessAction(company); }),
          catchError(error => of(new LoadCompanyFailureAction(error)))
        )
      )
    )
  );

}
