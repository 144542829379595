import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SocialMedia } from '../models/social-media';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getSocialMedia(): Observable<SocialMedia[]> {
    return this.httpClient.get<SocialMedia[]>("socialmedialinks");
  }
}
