<section class="pb-0 bg-img" [ngStyle]="{ 'background-image' : 'url(' + (section$ | async)?.background_image_url + ')' }">
    <div class="bg-overlay gradient-duetron-bg opacity-8"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="cover-text mt-5" *ngIf="!(loading$ | async)">
                    <h2 class="text-white mb-2">News Details</h2>
                    <p class="text-white">{{ (news$ | async)?.title }}</p>
                    <div class="page_nav">
                        <a routerLink="/" title="Home">Home </a>
                        <a routerLink="/news" title="News"> <i class="fa fa-angle-double-right"></i> News </a>
                        <span class="main-color"><i class="fa fa-angle-double-right"></i> {{ (news$ | async)?.title }} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="page-content" *ngIf="!(loading$ | async) && (news$ | async)">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="blog-detail-item row">
                    <div *ngIf="(news$ | async).image_url" class="blog-detail-img col-sm-12 mb-5 hover-effect">
                        <!-- <img src="assets/images/blog-detail-img1.jpg" alt="image"> -->
                        <img [src]="(news$ | async).image_url + '?width=1170&height=670'" [alt]="(news$ | async).title">
                    </div>
                    <div class="col-lg-8 mb-lg-0 mb-5 mb-xs-5">
                        <!--blog contetn-->
                        <div class="blog-item-content">
                            <div class="content-text">
                                <!-- <span class="category third-color">Food, Travel</span> - <span class="date">Dec 20, 2022</span> -->

                                <h4 class="mt-2 mb-4"><a [routerLink]="['/news/' + (news$ | async).slug]" [title]="(news$ | async).title">{{ (news$ | async).title }}</a></h4>
                                <div class="mb-4" [innerHTML]="(news$ | async).details" id="newsDetailsContent"></div>

                            </div>

                            <!--blog nav-->
                            <!-- <div class="blog-detail-nav">
                                <a [routerLink]="['/news/' + previous_news.slug]" *ngIf="(previous_news$ | json) != '{}'" [title]="previous_news.title">
                                    <h6 class="d-inline-block text-capitalize float-left">Previous News</h6>
                                </a>
                                <a [routerLink]="['/news/' + next_news.slug]" *ngIf="(next_news$ | json) != '{}'" [title]="next_news.title">
                                    <h6 class="d-inline-block text-capitalize float-right">Next News</h6>
                                </a>
                            </div> -->
                            <!--blog tags & share-->

                            <div class="blog-detail-tag d-flex justify-content-center justify-content-lg-end" *ngIf="!(loading$ | async)">
                                <share-buttons [theme]="'circles-light'" [include]="['facebook','twitter','linkedin','whatsapp','email']" [show]="5" [size]="-3" [url]="" [autoSetMeta]="true" [title]="(news$ | async).title" [description]="(news$ | async).description" [image]="(news$ | async).image_url">
                                </share-buttons>
                            </div>

                        </div>
                    </div>
                    <!--right side-->
                    <div class="col-lg-4">

                        <app-recent-news></app-recent-news>

                        <app-news-details-menu></app-news-details-menu>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>