<section class="pb-0 bg-img" [ngStyle]="{ 'background-image' : 'url(' + (page$ | async)?.background_image_url + ')' }">
    <div class="bg-overlay gradient-duetron-bg opacity-8"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="cover-text mt-5" *ngIf="!(loading$ | async)">
                    <h2 class="text-white mb-2">{{ (page$ | async).title }}</h2>
                    <!-- <p class="text-white">We offer the most creative web designs.</p> -->
                    <div class="page_nav">
                        <a routerLink="/" title="Home">Home </a>

                        <a *ngIf="(page$ | async).parent_id !== null" [routerLink]="[ '/pages/' + (page$ | async).parent_page.slug ]" [title]="(page$ | async).parent_page.title">
                            <i class="fa fa-angle-double-right"></i> {{ (page$ | async).parent_page.title }}
                        </a>

                        <span class="third-color"> <i class="fa fa-angle-double-right"></i> {{ (page$ | async).title}} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="blog" class="bg-light" *ngIf="!(loading$ | async) && (page$ | async).child_pages?.length > 0">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center wow fadeIn">
                <div class="title d-inline-block">
                    <!-- <h6 class="mb-3">Most flexible pricing</h6> -->
                    <h2 class="gradient-text1 mb-3">{{ (page$ | async).title }}</h2>
                    <p>{{ (page$ | async).description }}</p>
                </div>
            </div>
        </div>

        <div class="row align-items-center mb-5" *ngFor="let item of (page$ | async).child_pages; let i = index" [attr.data-index]="i">
            <div class="col-md-6 wow" [ngClass]="{ 'order-md-2 fadeInRight' : i % 2 === 0, 'fadeInLeft' : i % 2 === 1 }">
                <!--Blog Content-->
                <div class="blog-text">
                    <!-- <h6 class="third-color mb-3">{{ i }}</h6> -->
                    <h2>{{ item.title }}</h2>
                    <p>{{ item.description }}</p>
                    <a [routerLink]="[ '/pages/' + item.slug ]" [title]="item.title" class="btn btn-large btn-gradient btn-rounded mt-4">Learn More</a>
                </div>
            </div>
            <div class="col-md-6">
                <!--Blog Image-->
                <div class="blog-image wow hover-effect " [ngClass]="{ 'fadeInLeft' : i % 2 === 0, 'text-right fadeInRight' : i % 2 === 1 }">
                    <!-- <img [src]="'assets/images/blog-image' + (i + 1) + '.jpg'" [alt]="item.title"> -->
                    <img [src]="item?.image_url" [alt]="item.title">
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-md-12">
                <div class="blog-detail-tag d-flex justify-content-center" *ngIf="!(loading$ | async)">
                    <share-buttons [theme]="'circles-light'" [include]="['facebook','twitter','linkedin','whatsapp','email']" [show]="5" [size]="-3" [url]="" [autoSetMeta]="true" [title]="(page$ | async).title" [description]="(page$ | async).description" [image]="(page$ | async).image_url">
                    </share-buttons>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="page-content" *ngIf="!(loading$ | async) && (page$ | async).child_pages?.length === 0">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="blog-detail-item row">
                    <!-- <div class="blog-detail-img col-sm-12 mb-5 hover-effect" *ngIf="page.image_url !== null">
                        <img src="assets/images/blog-detail-img1.jpg" [alt]="page.title">
                    </div> -->
                    <div class="col-lg-12 mb-lg-0 mb-5 mb-xs-5">
                        <!--blog contetn-->
                        <div class="blog-item-content">
                            <div class="content-text">

                                <h4 class="gradient-text1 mb-4">
                                    <a [routerLink]="['/pages/' + (page$ | async).slug]" [title]="(page$ | async).title">
                                    {{ (page$ | async).title }}
                                  </a>
                                </h4>

                                <div class="mb-4" [innerHTML]="(page$ | async).details" id="pageDetailsContent"></div>

                            </div>

                            <!--blog tags & share-->
                            <div class="blog-detail-tag d-flex justify-content-center justify-content-lg-end" *ngIf="!(loading$ | async)">
                                <share-buttons [theme]="'circles-light'" [include]="['facebook','twitter','linkedin','whatsapp','email']" [show]="5" [size]="-3" [url]="" [autoSetMeta]="true" [title]="(page$ | async).title" [description]="(page$ | async).description" [image]="(page$ | async).image_url">
                                </share-buttons>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>