import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ServiceService } from 'src/app/services/service.service';
import {
  LoadServicesAction,
  LoadServicesFailureAction,
  LoadServicesSuccessAction,
  ServiceActionTypes
} from '../actions/service.actions';

@Injectable()
export class ServiceEffects {

  constructor(
    private actions$: Actions,
    private serviceService: ServiceService,
  ) { }

  loadServices$ = createEffect(() => this.actions$.pipe(
      ofType<LoadServicesAction>(ServiceActionTypes.LOAD_SERVICES),
      mergeMap(() => this.serviceService.getServices().pipe(
          map(services => { return new LoadServicesSuccessAction(services) }),
          catchError(error => of(new LoadServicesFailureAction(error)))
        )
      )
    )
  );

}
