import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { PageService } from 'src/app/services/page.service';
import { SeoService } from 'src/app/services/seo.service';
import {
  LoadPageAction,
  LoadPageFailureAction,
  LoadPageSuccessAction,
  PageActionTypes
} from '../actions/page.actions';

@Injectable()
export class PageEffects {

  constructor(
    private actions$: Actions,
    private pageService: PageService,
    private seoService: SeoService
  ) { }

  loadPage$ = createEffect(() => this.actions$.pipe(
      ofType<LoadPageAction>(PageActionTypes.LOAD_PAGE),
      mergeMap((data) => this.pageService.getPageBySlug(data.slug)
        .pipe(
          map(page => {
            if (data.autoSeo === true) {
              this.seoService.updateTitle('DUETRON - ' + page.title);
              this.seoService.updateMetaName('description', page.description);

              this.seoService.updateMetaProperty('og:title', page.title);
              this.seoService.updateMetaProperty('og:description', page.description);
              this.seoService.updateMetaProperty('og:image', page.image_url);
              this.seoService.updateMetaProperty('og:url', window.location.href);

              this.seoService.updateMetaName('twitter:title', page.title);
              this.seoService.updateMetaName('twitter:description', page.description);
              this.seoService.updateMetaName('twitter:image', page.image_url);
              this.seoService.updateMetaName('twitter:card', page.image_url);
            }

            return new LoadPageSuccessAction(page);
          }),
          catchError(error => of(new LoadPageFailureAction(error)))
        )
      )
    )
  );

}
