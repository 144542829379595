<section class="mb-5">
    <div class="container">

        <div class="row wow fadeIn">
            <div class="col-md-12 text-center">
                <div class="title d-inline-block">
                    <h6 class="mb-3 third-color">Have A Question ?</h6>
                    <h2 class="gradient-text1 mb-3">Get In Touch</h2>
                    <!-- <p>Teknomaks Bilişim Teknolojileri Sanayi ve Dış Tic. Ltd. Şti.</p> -->
                </div>
            </div>
        </div>

        <form class="contact-form" [formGroup]="contactFormGroup" (ngSubmit)="sendMessage()">
            <div class="row">

                <div class="col-sm-12" id="result"></div>

                <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Your Name:" required="" id="name" [ngClass]="{'is-invalid': contactFormGroup.get('name').errors && contactFormGroup.get('name').touched}" formControlName="name">

                        <div class="invalid-feedback" *ngIf="contactFormGroup.get('name').touched && contactFormGroup.get('name').hasError('required')">
                            The name field is required!
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                        <input class="form-control" type="email" placeholder="Email:" required="" id="email" [ngClass]="{'is-invalid': contactFormGroup.get('email').errors && contactFormGroup.get('email').touched}" formControlName="email" email>
                        <div class="invalid-feedback" *ngIf="contactFormGroup.get('email').touched && contactFormGroup.get('email').hasError('required')">
                            The email field is required!
                        </div>
                        <div class="invalid-feedback" *ngIf="contactFormGroup.get('email').touched && contactFormGroup.get('email').hasError('pattern')">
                            The email address is invalid!
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Subject:" required="" id="subject" [ngClass]="{'is-invalid': contactFormGroup.get('subject').errors && contactFormGroup.get('subject').touched}" formControlName="subject">
                        <div class="invalid-feedback" *ngIf="contactFormGroup.get('subject').touched && contactFormGroup.get('subject').invalid">
                            The message subject field is required!
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                        <input class="form-control" type="tel" placeholder="Phone:" id="phone" [ngClass]="{'is-invalid': contactFormGroup.get('phone').errors && contactFormGroup.get('phone').touched}" formControlName="phone">
                        <div class="invalid-feedback" *ngIf="contactFormGroup.get('phone').touched && contactFormGroup.get('phone').hasError('required')">
                            The phone number field is required!
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-sm-12">
                    <div class="form-group">
                        <textarea class="form-control" placeholder="Message" id="message" name="message" [ngClass]="{'is-invalid': contactFormGroup.get('message').errors && contactFormGroup.get('message').touched}" formControlName="message"></textarea>
                        <div class="invalid-feedback" *ngIf="contactFormGroup.get('message').touched && contactFormGroup.get('message').hasError('required')">
                            The message field is required!
                        </div>
                    </div>
                </div>

                <div class="col-sm-12">
                    <button type="submit" class="btn btn-large btn-gradient mt-4" [disabled]="!contactFormGroup.valid || sending" [ngClass]="{'not-allowed' : !contactFormGroup.valid || sending}">
                      <i class="fa fa-spinner fa-spin mr-2" aria-hidden="true" *ngIf="sending"></i>
                      <span>
                        {{ sending === true ? 'Sending' : 'Contact Now' }}
                      </span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</section>