import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ProductService } from 'src/app/services/product.service';
import { SeoService } from 'src/app/services/seo.service';
import {
  LoadProductAction,
  LoadProductFailureAction,
  LoadProductSuccessAction,
  ProductActionTypes
} from '../actions/product.actions';

@Injectable()
export class ProductEffects {

  constructor(
    private actions$: Actions,
    private productService: ProductService,
    private seoService: SeoService
  ) { }

  loadProduct$ = createEffect(() => this.actions$.pipe(
      ofType<LoadProductAction>(ProductActionTypes.LOAD_PRODUCT),
      mergeMap((data) => this.productService.getProductBySlug(data.slug)
        .pipe(
          map(product => {
            this.seoService.updateTitle('DUETRON - ' + product.title);
            this.seoService.updateMetaName('description', product.description);

            this.seoService.updateMetaProperty('og:title', product.title);
            this.seoService.updateMetaProperty('og:description', product.description);
            this.seoService.updateMetaProperty('og:image', product.image_url);
            this.seoService.updateMetaProperty('og:url', window.location.href);

            this.seoService.updateMetaName('twitter:title', product.title);
            this.seoService.updateMetaName('twitter:description', product.description);
            this.seoService.updateMetaName('twitter:image', product.image_url);
            this.seoService.updateMetaName('twitter:card', product.image_url);

            return new LoadProductSuccessAction(product)
          }),
          catchError(error => of(new LoadProductFailureAction(error)))
        )
      )
    )
  );

}
