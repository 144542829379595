<section class="single-items center-block parallax bg-img" id="social" [ngStyle]="{ 'background-image' : 'url(' + (product$ | async)?.image_url + ')' }">
    <div class="bg-overlay gradient-duetron-bg opacity-7"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12 center-col">
                <div class="area-heading text-left wow fadeInRight">
                    <h3 class="area-title text-capitalize alt-font text-white mb-2 font-weight-100">
                        <a href="#product_details" [title]="(product$ | async)?.title" (click)="print($event)"><strong> {{ (product$ | async)?.title }}</strong></a>
                    </h3>

                    <div [innerHTML]="(product$ | async)?.description" class="text-white" id="product_description"></div>

                    <a class="btn btn-transparent-white btn-rounded btn-large mt-4" data-fancybox="group-four" href="#product_details" (click)="print($event)">
                    View More <i class="fa fa-long-arrow-down"></i>
                  </a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <ng-container *ngFor="let product_section of (product$ | async).product_sections">
    <ng-component class="page-content" [innerHTML]="product_section.content | safeHtml"></ng-component>
</ng-container> -->

<section class="page-content" id="product_details">
    <div class="container">
        <div class="row" *ngIf="!(loading$ | async)">
            <div class="col-sm-12">
                <div class="blog-detail-item row">
                    <!-- <div class="blog-detail-img col-sm-12 mb-5 hover-effect">
                        <img src="images/blog-detail-img1.jpg" alt="image">
                    </div> -->
                    <div class="col-lg-12 mb-lg-0 mb-5 mb-xs-5">
                        <!--blog contetn-->
                        <div class="blog-item-content">
                            <div class="content-text">
                                <!-- <span class="category third-color">Food, Travel</span> - <span class="date">Dec 20, 2022</span> -->
                                <h3 class="mt-2 mb-4 gradient-text1">
                                    <a [routerLink]="[ '/products/' + (product$ | async).slug ]" [title]="(product$ | async).title">
                                      {{ (product$ | async).title }}
                                    </a>
                                </h3>
                                <div class="mb-4" [innerHTML]="(product$ | async).details | safeHtml" id="productDetailsContent"></div>
                            </div>

                            <div class="blog-detail-tag  d-flex justify-content-center justify-content-lg-end" *ngIf="!(loading$ | async)">
                                <share-buttons [theme]="'circles-light'" [include]="['facebook','twitter','linkedin','whatsapp','email']" [show]="5" [size]="-3" [url]="" [autoSetMeta]="true" [title]="(product$ | async).title" [description]="(product$ | async).description" [image]="(product$ | async).image_url">
                                </share-buttons>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>