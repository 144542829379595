import { Action } from '@ngrx/store';
import { Company } from 'src/app/models/company';

export enum CompanyActionTypes {
  LOAD_COMPANIES = '[COMPANY] Load Companies',
  LOAD_COMPANIES_SUCCESS = '[COMPANY] Load Companies Success',
  LOAD_COMPANIES_FAILURE = '[COMPANY] Load Companies Failure',
  LOAD_COMPANY = '[COMPANY] Load Company',
  LOAD_COMPANY_SUCCESS = '[COMPANY] Load Company Success',
  LOAD_COMPANY_FAILURE = '[COMPANY] Load Company Failure'
}

export class LoadCompaniesAction implements Action {
  readonly type = CompanyActionTypes.LOAD_COMPANIES;
}

export class LoadCompaniesSuccessAction implements Action {
  readonly type = CompanyActionTypes.LOAD_COMPANIES_SUCCESS;

  constructor(public payload: Company[]) {}
}

export class LoadCompaniesFailureAction implements Action {
  readonly type = CompanyActionTypes.LOAD_COMPANIES_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadCompanyAction implements Action {
  readonly type = CompanyActionTypes.LOAD_COMPANY;
}

export class LoadCompanySuccessAction implements Action {
  readonly type = CompanyActionTypes.LOAD_COMPANY_SUCCESS;

  constructor(public payload: Company) {}
}

export class LoadCompanyFailureAction implements Action {
  readonly type = CompanyActionTypes.LOAD_COMPANY_FAILURE;

  constructor(public payload: Error) {}
}

export type CompanyAction = LoadCompaniesAction |
LoadCompaniesSuccessAction |
LoadCompaniesFailureAction |
LoadCompanyAction |
LoadCompanySuccessAction |
LoadCompanyFailureAction;
