import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseMenu } from '../models/base-menu';
import { Menu } from '../models/menu';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getHeaderMenu(): Observable<Menu[]> {
    return this.httpClient.get<Menu[]>("headermenus");
  }

  getSideMenu(): Observable<Menu[]> {
    return this.httpClient.get<Menu[]>("sidemenus");
  }

  getNewsMenu(): Observable<BaseMenu[]> {
    return this.httpClient.get<BaseMenu[]>("newsmenus");
  }

  getNewsDetailsMenu(): Observable<BaseMenu[]> {
    return this.httpClient.get<BaseMenu[]>("newsdetailsmenus");
  }
}
