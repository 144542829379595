import { Action } from '@ngrx/store';
import { Slider } from 'src/app/models/slider';

export enum SliderActionTypes {
  LOAD_SLIDERS= '[SLIDER] Load Sliders',
  LOAD_SLIDERS_SUCCESS = '[SLIDER] Load Sliders Success',
  LOAD_SLIDERS_FAILURE = '[SLIDER] Load Sliders Failure',
}

export class LoadSlidersAction implements Action {
  readonly type = SliderActionTypes.LOAD_SLIDERS;
}

export class LoadSlidersSuccessAction implements Action {
  readonly type = SliderActionTypes.LOAD_SLIDERS_SUCCESS;

  constructor(public payload: Array<Slider>) {}
}

export class LoadSlidersFailureAction implements Action {
  readonly type = SliderActionTypes.LOAD_SLIDERS_FAILURE;

  constructor(public payload: Error) {}
}

export type SliderAction = LoadSlidersAction |
LoadSlidersSuccessAction |
LoadSlidersFailureAction;
