import { Action } from '@ngrx/store';
import { SocialMedia } from 'src/app/models/social-media';

export enum SocialMediaActionTypes {
  LOAD_SOCIAL_MEDIAS= '[SOCIALMEDIA] Load Social Medias',
  LOAD_SOCIAL_MEDIAS_SUCCESS = '[SOCIALMEDIA] Load Social Medias Success',
  LOAD_SOCIAL_MEDIAS_FAILURE = '[SOCIALMEDIA] Load Social Medias Failure',
}

export class LoadSocialMediasAction implements Action {
  readonly type = SocialMediaActionTypes.LOAD_SOCIAL_MEDIAS;
}

export class LoadSocialMediasSuccessAction implements Action {
  readonly type = SocialMediaActionTypes.LOAD_SOCIAL_MEDIAS_SUCCESS;

  constructor(public payload: Array<SocialMedia>) {}
}

export class LoadSocialMediasFailureAction implements Action {
  readonly type = SocialMediaActionTypes.LOAD_SOCIAL_MEDIAS_FAILURE;

  constructor(public payload: Error) {}
}

export type SocialMediaAction = LoadSocialMediasAction |
LoadSocialMediasSuccessAction |
LoadSocialMediasFailureAction;
