import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SocialMedia } from 'src/app/models/social-media';
import { LoadSocialMediasAction } from 'src/app/store/actions/social-media.actions';
import { AppState } from 'src/app/store/models/app-state.model';

@Component({
  selector: 'app-side-footer',
  templateUrl: './side-footer.component.html',
  styleUrls: ['./side-footer.component.scss']
})
export class SideFooterComponent implements OnInit {

  social_media_links$: Observable<SocialMedia[]> = this.store.select(state => state.socialMedia.social_medias);
  loading$: Observable<boolean> = this.store.select(state => state.socialMedia.social_medias_loading);
  error$: Observable<Error> = this.store.select(state => state.socialMedia.error);

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.getSocialMediaLinks();
  }

  getSocialMediaLinks() {
    this.store.dispatch(new LoadSocialMediasAction());
  }
}
