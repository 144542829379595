import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { NewsService } from 'src/app/services/news.service';
import { SeoService } from 'src/app/services/seo.service';
import {
  LoadAllNewsAction,
  LoadAllNewsFailureAction,
  LoadAllNewsSuccessAction,
  LoadNewsAction,
  LoadNewsFailureAction,
  LoadNewsSuccessAction,
  LoadRecentNewsAction,
  LoadRecentNewsFailureAction,
  LoadRecentNewsSuccessAction,
  NewsActionTypes
} from '../actions/news.actions';

@Injectable()
export class NewsEffects {

  constructor(
    private actions$: Actions,
    private newsService: NewsService,
    private seoService: SeoService
  ) { }

  loadAllNews$ = createEffect(() => this.actions$.pipe(
      ofType<LoadAllNewsAction>(NewsActionTypes.LOAD_ALL_NEWS),
      mergeMap(() => this.newsService.getNews()
        .pipe(
          map(allNews => { return new LoadAllNewsSuccessAction(allNews); }),
          catchError(error => of(new LoadAllNewsFailureAction(error)))
        )
      )
    )
  );

  loadRecentNews$ = createEffect(() => this.actions$.pipe(
      ofType<LoadRecentNewsAction>(NewsActionTypes.LOAD_RECENT_NEWS),
      mergeMap(() => this.newsService.getRecentNews()
        .pipe(
          map(recentNews => { return new LoadRecentNewsSuccessAction(recentNews); }),
          catchError(error => of(new LoadRecentNewsFailureAction(error)))
        )
      )
    )
  );

  loadNews$ = createEffect(() => this.actions$.pipe(
      ofType<LoadNewsAction>(NewsActionTypes.LOAD_NEWS),
      mergeMap((data) => this.newsService.getNewsBySlug(data.slug)
        .pipe(
          map(response => {
            this.seoService.updateTitle('DUETRON - ' + response['news']['title']);
            this.seoService.updateMetaName('description', response['news']['description']);

            this.seoService.updateMetaProperty('og:title', response['news']['title']);
            this.seoService.updateMetaProperty('og:description', response['news']['description']);
            this.seoService.updateMetaProperty('og:image', response['news']['image_url']);
            this.seoService.updateMetaProperty('og:url', window.location.href);

            this.seoService.updateMetaName('twitter:title', response['news']['title']);
            this.seoService.updateMetaName('twitter:description', response['news']['description']);
            this.seoService.updateMetaName('twitter:image', response['news']['image_url']);
            this.seoService.updateMetaName('twitter:card', response['news']['image_url']);

            return new LoadNewsSuccessAction(response['news']);
          }),
          catchError(error => of(new LoadNewsFailureAction(error)))
        )
      )
    )
  );

}
