import { Page } from 'src/app/models/page';
import { PageAction, PageActionTypes } from '../actions/page.actions';

export interface PageState {
  page: Page,
  page_loading: boolean,
  error: Error
};

const initialState: PageState = {
  page: undefined,
  page_loading: false,
  error: undefined
};

export function PageReducer(state: PageState, action: PageAction) {
  switch (action.type) {
    case PageActionTypes.LOAD_PAGE:
      return {
        ...state,
        page_loading: true
      };
    case PageActionTypes.LOAD_PAGE_SUCCESS:
      return {
        ...state,
        page: action.payload,
        page_loading: false
      };
    case PageActionTypes.LOAD_PAGE_FAILURE:
      return {
        ...state,
        error: action.payload,
        page_loading: false
      };
    default:
      return state;
  }
}
