<div class="side-menu">
    <div class="inner-wrapper">
        <span class="btn-close link" id="btn_sideNavClose" (click)="closeSideMenu($event)"></span>

        <nav class="side-nav w-100">
            <ul class="navbar-nav" *ngIf="!(side_menus_loading$ | async)">
                <li class="nav-item" *ngFor="let item of side_menus$ | async">
                    <a class="nav-link" [routerLink]="item.navigation_url" [title]="item.title" routerLinkActive="active">
                      {{ item.title }}
                    </a>
                </li>
            </ul>
        </nav>

        <app-side-footer></app-side-footer>
    </div>
</div>
<a id="close_side_menu" href="javascript:void(0);"></a>