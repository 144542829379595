import { BaseMenu } from 'src/app/models/base-menu';
import { Menu } from 'src/app/models/menu';
import { MenuAction, MenuActionTypes } from '../actions/menu.actions';

export interface MenuState {
  header_menus: Menu[],
  header_menus_loading: boolean,
  header_menus_error: Error,

  side_menus: Menu[],
  side_menus_loading: boolean,
  side_menus_error: Error,

  news_menus: BaseMenu[],
  news_menus_loading: boolean,
  news_menus_error: Error,

  news_details_menus: BaseMenu[],
  news_details_menus_loading: boolean,
  news_details_menus_error: Error
};

const initialState: MenuState = {
  header_menus: [] = [],
  header_menus_loading: false,
  header_menus_error: undefined,

  side_menus: [] = [],
  side_menus_loading: false,
  side_menus_error: undefined,

  news_menus: [] = [],
  news_menus_loading: false,
  news_menus_error: undefined,

  news_details_menus: [] = [],
  news_details_menus_loading: false,
  news_details_menus_error: undefined
};

export function MenuReducer(state: MenuState = initialState, action: MenuAction) {
  switch (action.type) {
    case MenuActionTypes.LOAD_HEADER_MENUS:
      return {
        ...state,
        header_menus_loading: true
      };
    case MenuActionTypes.LOAD_HEADER_MENUS_SUCCESS:
      return {
        ...state,
        header_menus: action.payload,
        header_menus_loading: false
      };
    case MenuActionTypes.LOAD_HEADER_MENUS_FAILURE:
      return {
        ...state,
        header_menus_error: action.payload,
        header_menus_loading: false
      };

    case MenuActionTypes.LOAD_SIDE_MENUS:
      return {
        ...state,
        side_menus_loading: true
      };
    case MenuActionTypes.LOAD_SIDE_MENUS_SUCCESS:
      return {
        ...state,
        side_menus: action.payload,
        side_menus_loading: false
      };
    case MenuActionTypes.LOAD_SIDE_MENUS_FAILURE:
      return {
        ...state,
        side_menus_error: action.payload,
        side_menus_loading: false
      };

    case MenuActionTypes.LOAD_NEWS_MENUS:
      return {
        ...state,
        news_menus_loading: true
      };
    case MenuActionTypes.LOAD_NEWS_MENUS_SUCCESS:
      return {
        ...state,
        news_menus: action.payload,
        news_menus_loading: false
      };
    case MenuActionTypes.LOAD_NEWS_MENUS_FAILURE:
      return {
        ...state,
        news_menus_error: action.payload,
        news_menus_loading: false
      };

    case MenuActionTypes.LOAD_NEWS_DETAILS_MENUS:
      return {
        ...state,
        news_details_menus_loading: true
      };
    case MenuActionTypes.LOAD_NEWS_DETAILS_MENUS_SUCCESS:
      return {
        ...state,
        news_details_menus: action.payload,
        news_details_menus_loading: false
      };
    case MenuActionTypes.LOAD_NEWS_DETAILS_MENUS_FAILURE:
      return {
        ...state,
        news_details_menus_error: action.payload,
        news_details_menus_loading: false
      };

    default:
      return state;
  }
}
