<section id="blog" class="bg-light">
    <div class="container" *ngIf="!(loading$ | async)">
        <div class="row">
            <div class="col-md-12 text-center wow fadeIn" style="visibility: visible; animation-name: fadeIn;">
                <div class="title d-inline-block">
                    <!-- <h6 class="mb-3">Most reliable application</h6> -->
                    <h2 class="gradient-text1 mb-3">{{ (page$ | async).title }}</h2>
                    <p>{{ (page$ | async).description }}</p>
                </div>
            </div>
        </div>

        <div class="row align-items-center mb-5" *ngFor="let item of (page$ | async).child_pages; let i = index" [attr.data-index]="i">
            <div class="col-md-6 wow" [ngClass]="{ 'order-md-2 fadeInRight' : i % 2 === 0, 'fadeInLeft' : i % 2 === 1 }">
                <div class="blog-text">
                    <h6 class="third-color mb-3">{{ (page$ | async).title }}</h6>
                    <h2>{{ item.title }}</h2>
                    <p>{{ item.description }}</p>
                    <a [routerLink]="[ '/pages/' + item.slug ]" [title]="item.title" class="btn btn-large btn-gradient btn-rounded mt-4">Learn More</a>
                </div>
            </div>
            <div class="col-md-6">
                <div class="blog-image wow hover-effect " [ngClass]="{ 'fadeInLeft' : i % 2 === 0, 'text-right fadeInRight' : i % 2 === 1 }">
                    <img [src]="item?.image_url" [alt]="item.title">
                </div>
            </div>
        </div>
    </div>
</section>