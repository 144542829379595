import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Menu } from 'src/app/models/menu';
import { LoadHeaderMenusAction } from 'src/app/store/actions/menu.actions';
import { AppState } from 'src/app/store/models/app-state.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  header_menus$: Observable<Menu[]> = this.store.select(state => state.menu.header_menus);
  header_menus_loading$: Observable<boolean> = this.store.select(state => state.menu.header_menus_loading);
  header_menus_error$: Observable<Error> = this.store.select(state => state.menu.header_menus_error);

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.getHeaderMenus();
  }

  getHeaderMenus() {
    this.store.dispatch(new LoadHeaderMenusAction());
  }

  openSideMenu(event) {
    $(".pushwrap").toggleClass("active");
    $(".side-menu").addClass("side-menu-active"), $("#close_side_menu").fadeIn(700);
  }
}
