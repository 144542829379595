<section class="pb-0 bg-img news-title" [ngStyle]="{ 'background-image' : 'url(' + (section$ | async)?.background_image_url + ')' }">
    <div class="bg-overlay gradient-duetron-bg opacity-8"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="cover-text mt-5">
                    <h2 class="text-white mb-2">News</h2>
                    <!-- <p class="text-white">We offer the most creative web designs.</p> -->
                    <p class="text-white"></p>
                    <div class="page_nav">
                        <a routerLink="/" title="Home">Home </a>
                        <span class="third-color"> <i class="fa fa-angle-double-right"></i> News </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">

            <div class="col-lg-8 mb-xs-5" *ngIf="!(news_loading$ | async)">

                <div class="blog-list-item" *ngFor="let item of pageOfItems">

                    <a [routerLink]="['/news/' + item.slug]" *ngIf="item.image_url !== null" [title]="item.title">
                        <div class="blog-item-img mb-5 hover-effect">
                            <img [src]="item?.image_url + '?width=775&height=515'" [alt]="item.title">
                        </div>
                    </a>

                    <div class="blog-item-content">
                        <!-- <span class="category third-color tex">Food, Travel</span> - <span class="date">Dec 20, 2022</span> -->
                        <h5 class="mt-2 mb-3"><a [routerLink]="['/news/' + item.slug]" [title]="item.title">{{ item.title }}</a></h5>
                        <p class="mb-4">{{ item.description }}</p>
                        <!--button-->
                        <a [routerLink]="['/news/' + item.slug]" [title]="item.title" class="btn btn-large btn-gradient btn-rounded">Read More</a>
                    </div>
                </div>

                <!--pagination-->

                <app-pagination [items]="(news$ | async)" (changePage)="onChangePage($event)" [pageSize]="4"></app-pagination>

            </div>

            <!--right side-->
            <div class="col-lg-4">
                <!--search-->
                <!-- <div class="widget d-flex bg-light mb-2">
                    <input class="search" placeholder="Search.." type="text">
                    <a href="#." class="search-btn"><i class="fa fa-search" aria-hidden="true"></i></a>
                </div> -->

                <!--recent news-->
                <app-recent-news></app-recent-news>

                <!-- news menu -->
                <app-news-menu></app-news-menu>

            </div>


        </div>
    </div>
</section>