import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Service } from 'src/app/models/service';
import { LoadServicesAction } from 'src/app/store/actions/service.actions';
import { AppState } from 'src/app/store/models/app-state.model';

@Component({
  selector: 'app-home-service',
  templateUrl: './home-service.component.html',
  styleUrls: ['./home-service.component.scss']
})
export class HomeServiceComponent implements OnInit {

  services$: Observable<Service[]> = this.store.select(state => state.service.services);
  services_loading$: Observable<boolean> = this.store.select(state => state.service.services_loading);
  services_error$: Observable<Error> = this.store.select(state => state.service.error);

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.getServices();
  }

  getServices() {
    this.store.dispatch(new LoadServicesAction());
  }

}
