import { Product } from 'src/app/models/product';
import { ProductAction, ProductActionTypes } from '../actions/product.actions';

export interface ProductState {
  product: Product,
  product_loading: boolean,
  error: Error
};

const initialState: ProductState = {
  product: undefined,
  product_loading: false,
  error: undefined
};

export function ProductReducer(state: ProductState, action: ProductAction) {
  switch (action.type) {
    case ProductActionTypes.LOAD_PRODUCT:
      return {
        ...state,
        product_loading: true
      };
    case ProductActionTypes.LOAD_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.payload,
        product_loading: false
      };
    case ProductActionTypes.LOAD_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload,
        product_loading: false
      };
    default:
      return state;
  }
}
