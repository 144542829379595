import { Action } from '@ngrx/store';
import { BaseMenu } from 'src/app/models/base-menu';
import { Menu } from 'src/app/models/menu';

export enum MenuActionTypes {
  LOAD_HEADER_MENUS = '[MENU] Load Header Menus',
  LOAD_HEADER_MENUS_SUCCESS = '[MENU] Load Header Menus Success',
  LOAD_HEADER_MENUS_FAILURE = '[MENU] Load Header Menus Failure',

  LOAD_SIDE_MENUS = '[MENU] Load Side Menus',
  LOAD_SIDE_MENUS_SUCCESS = '[MENU] Load Side Menus Success',
  LOAD_SIDE_MENUS_FAILURE = '[MENU] Load Side Menus Failure',

  LOAD_NEWS_MENUS = '[BASEMENU] Load News Menus',
  LOAD_NEWS_MENUS_SUCCESS = '[BASEMENU] Load News Menus Success',
  LOAD_NEWS_MENUS_FAILURE = '[BASEMENU] Load News Menus Failure',

  LOAD_NEWS_DETAILS_MENUS = '[MENU] Load News Details Menus',
  LOAD_NEWS_DETAILS_MENUS_SUCCESS = '[MENU] Load News Details Menus Success',
  LOAD_NEWS_DETAILS_MENUS_FAILURE = '[MENU] Load News Details Menus Failure',
}

export class LoadHeaderMenusAction implements Action {
  readonly type = MenuActionTypes.LOAD_HEADER_MENUS;
}

export class LoadHeaderMenusSuccessAction implements Action {
  readonly type = MenuActionTypes.LOAD_HEADER_MENUS_SUCCESS;

  constructor(public payload: Array<Menu>) {}
}

export class LoadHeaderMenusFailureAction implements Action {
  readonly type = MenuActionTypes.LOAD_HEADER_MENUS_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadSideMenusAction implements Action {
  readonly type = MenuActionTypes.LOAD_SIDE_MENUS;
}

export class LoadSideMenusSuccessAction implements Action {
  readonly type = MenuActionTypes.LOAD_SIDE_MENUS_SUCCESS;

  constructor(public payload: Array<Menu>) {}
}

export class LoadSideMenusFailureAction implements Action {
  readonly type = MenuActionTypes.LOAD_SIDE_MENUS_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadNewsMenusAction implements Action {
  readonly type = MenuActionTypes.LOAD_NEWS_MENUS;
}

export class LoadNewsMenusSuccessAction implements Action {
  readonly type = MenuActionTypes.LOAD_NEWS_MENUS_SUCCESS;

  constructor(public payload: Array<BaseMenu>) {}
}

export class LoadNewsMenusFailureAction implements Action {
  readonly type = MenuActionTypes.LOAD_NEWS_MENUS_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadNewsDetailsMenusAction implements Action {
  readonly type = MenuActionTypes.LOAD_NEWS_DETAILS_MENUS;
}

export class LoadNewsDetailsMenusSuccessAction implements Action {
  readonly type = MenuActionTypes.LOAD_NEWS_DETAILS_MENUS_SUCCESS;

  constructor(public payload: Array<BaseMenu>) {}
}

export class LoadNewsDetailsMenusFailureAction implements Action {
  readonly type = MenuActionTypes.LOAD_NEWS_DETAILS_MENUS_FAILURE;

  constructor(public payload: Error) {}
}

export type MenuAction = LoadHeaderMenusAction |
LoadHeaderMenusSuccessAction |
LoadHeaderMenusFailureAction |
LoadSideMenusAction |
LoadSideMenusSuccessAction |
LoadSideMenusFailureAction |
LoadNewsMenusAction |
LoadNewsMenusSuccessAction |
LoadNewsMenusFailureAction |
LoadNewsDetailsMenusAction |
LoadNewsDetailsMenusSuccessAction |
LoadNewsDetailsMenusFailureAction;
