import { Company } from 'src/app/models/company';
import { CompanyAction, CompanyActionTypes } from '../actions/company.actions';

export interface CompanyState {
  companies: Company[],
  companies_loading: boolean,
  companies_error: Error,
  company: Company,
  company_loading: boolean,
  company_error: Error
};

const initialState: CompanyState = {
  companies: [] = [],
  companies_loading: false,
  companies_error: undefined,
  company: undefined,
  company_loading: false,
  company_error: undefined
};

export function CompanyReducer(state: CompanyState = initialState, action: CompanyAction) {
  switch (action.type) {
    case CompanyActionTypes.LOAD_COMPANIES:
      return {
        ...state,
        companies_loading: true
      };
    case CompanyActionTypes.LOAD_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        companies_loading: false
      };
    case CompanyActionTypes.LOAD_COMPANIES_FAILURE:
      return {
        ...state,
        companies_error: action.payload,
        companies_loading: false
      };
    case CompanyActionTypes.LOAD_COMPANY:
      return {
        ...state,
        company_loading: true
      };
    case CompanyActionTypes.LOAD_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload,
        company_loading: false
      };
    case CompanyActionTypes.LOAD_COMPANY_FAILURE:
      return {
        ...state,
        company_error: action.payload,
        company_loading: false
      };
    default:
      return state;
  }
}
