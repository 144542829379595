import { Action } from '@ngrx/store';
import { Page } from 'src/app/models/page';

export enum PageActionTypes {
  LOAD_PAGE = '[PAGE] Load Page',
  LOAD_PAGE_SUCCESS = '[PAGE] Load Page Success',
  LOAD_PAGE_FAILURE = '[PAGE] Load Page Failure',
}

export class LoadPageAction implements Action {
  readonly type = PageActionTypes.LOAD_PAGE;

  constructor(public slug: string, public autoSeo: boolean = true) {}
}

export class LoadPageSuccessAction implements Action {
  readonly type = PageActionTypes.LOAD_PAGE_SUCCESS;

  constructor(public payload: Page) {}
}

export class LoadPageFailureAction implements Action {
  readonly type = PageActionTypes.LOAD_PAGE_FAILURE;

  constructor(public payload: Error) {}
}

export type PageAction = LoadPageAction |
LoadPageSuccessAction |
LoadPageFailureAction;
