import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SocialMediaService } from 'src/app/services/social-media.service';
import {
  LoadSocialMediasAction,
  LoadSocialMediasFailureAction,
  LoadSocialMediasSuccessAction,
  SocialMediaActionTypes
} from '../actions/social-media.actions';

@Injectable()
export class SocialMediaEffects {

  constructor(
    private actions$: Actions,
    private socialMediaService: SocialMediaService,
  ) { }

  loadSocialMedias$ = createEffect(() => this.actions$.pipe(
      ofType<LoadSocialMediasAction>(SocialMediaActionTypes.LOAD_SOCIAL_MEDIAS),
      mergeMap(() => this.socialMediaService.getSocialMedia()
        .pipe(
          map(socialMediaLinks => { return new LoadSocialMediasSuccessAction(socialMediaLinks) }),
          catchError(error => of(new LoadSocialMediasFailureAction(error)))
        )
      )
    )
  );

}
