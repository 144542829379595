import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SliderService } from 'src/app/services/slider.service';
import {
  LoadSlidersAction,
  LoadSlidersFailureAction,
  LoadSlidersSuccessAction,
  SliderActionTypes
} from '../actions/slider.actions';

@Injectable()
export class SliderEffects {

  constructor(
    private actions$: Actions,
    private sliderService: SliderService,
  ) { }

  loadSliders$ = createEffect(() => this.actions$.pipe(
      ofType<LoadSlidersAction>(SliderActionTypes.LOAD_SLIDERS),
      mergeMap(() => this.sliderService.getSliders().pipe(
          map(sliders => { return new LoadSlidersSuccessAction(sliders) }),
          catchError(error => of(new LoadSlidersFailureAction(error)))
        )
      )
    )
  );

  // @Effect() loadSliders$ = this.actions$.pipe(
  //   ofType<LoadSlidersAction>(SliderActionTypes.LOAD_SLIDERS),
  //   mergeMap(
  //     () => this.sliderService.getSliders().pipe(
  //       map(data => {
  //         return new LoadSlidersSuccessAction(data)
  //       }),
  //       catchError(error => of(new LoadSlidersFailureAction(error)))
  //     )
  //   )
  // );
}
