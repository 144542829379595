<section class="pb-0 bg-img" [ngStyle]="{ 'background-image' : 'url(' + (section$ | async)?.background_image_url + ')' }">
    <div class="bg-overlay gradient-duetron-bg opacity-8"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="cover-text mt-5">
                    <h2 class="text-white mb-2">Contact Us</h2>
                    <!-- <p class="text-white">We offer the most creative web designs.</p> -->
                    <div class="page_nav">
                        <a routerLink="/" title="Home">Home </a>
                        <span class="third-color"> <i class="fa fa-angle-double-right"></i> Contact Us</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-contact-info></app-contact-info>

<app-contact-form></app-contact-form>