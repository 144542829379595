import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Slider } from 'src/app/models/slider';
import { LoadSlidersAction } from 'src/app/store/actions/slider.actions';
import { AppState } from 'src/app/store/models/app-state.model';

declare var $: any;
// import * as $ from 'jquery';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, AfterViewInit {

  @ViewChild("rev_slider") revSliderElementView: ElementRef;

  sliders$: Observable<Slider[]> = this.store.select(state => state.slider.sliders);
  loading$: Observable<boolean> = this.store.select(state => state.slider.sliders_loading);
  error$: Observable<Error> = this.store.select(state => state.slider.error);

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.getSliders();
  }

  ngAfterViewInit(): void {
    this.sliders$.subscribe(data => {
      if (0 < data.length) {
        setTimeout(() => {
          this.updateSliderJavaScript();
        }, 1500);
      }
    });
  }

  getSliders() {
    this.store.dispatch(new LoadSlidersAction());
  }

  gotoSection(event) {

    $("html,body").animate({
        scrollTop: $("#about").offset().top - 60
    }, 1200);

    event.preventDefault();
  }

  updateSliderJavaScript() {
    $(this.revSliderElementView.nativeElement).show().revolution({
      sliderType: "standard",
      jsFileLocation: "//localhost:82/revslider/revslider/public/assets/js/",
      sliderLayout: "fullscreen",
      dottedOverlay: "none",
      delay: 9000,
      navigation: {
        keyboardNavigation: "off",
        keyboard_direction: "horizontal",
        mouseScrollNavigation: "off",
        mouseScrollReverse: "default",
        onHoverStop: "off",
        bullets: {
          enable: true,
          hide_onmobile: true,
          hide_under: 767,
          style: "wexim",
          hide_onleave: false,
          direction: "vertical",
          h_align: "left",
          v_align: "center",
          h_offset: 30,
          v_offset: 0,
          space: 5,
          tmp: '<div class="tp-bullet-inner"></div><div class="tp-line"></div>'
        },
        touch: {
          touchenabled: "on",
          swipe_threshold: 75,
          swipe_min_touches: 1,
          swipe_direction: "horizontal",
          drag_block_vertical: false
        },
      },
      responsiveLevels: [1900, 1600, 1200, 1024, 778, 580],
      visibilityLevels: [1900, 1600, 1024, 778, 580],
      gridwidth: [1100, 1200, 1140, 960, 750, 480],
      gridheight: [868, 768, 960, 720],
      lazyType: "none",
      scrolleffect: {
        on_slidebg: "on",
      },
      parallax: {
        type: "mouse",
        origo: "slidercenter",
        speed: 2000,
        speedbg: 0,
        speedls: 0,
        levels: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        disable_onmobile: "on"
      },
      shadow: 0,
      spinner: "off",
      stopLoop: "off",
      stopAfterLoops: -1,
      stopAtSlide: -1,
      shuffle: "off",
      autoHeight: "off",
      fullScreenAutoWidth: "off",
      fullScreenAlignForce: "off",
      fullScreenOffsetContainer: "",
      fullScreenOffset: "",
      disableProgressBar: "on",
      hideThumbsOnMobile: "on",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        nextSlideOnWindowFocus: "off",
        disableFocusListener: false,
      }
    });
  }
}
