import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Menu } from 'src/app/models/menu';
import { LoadSideMenusAction } from 'src/app/store/actions/menu.actions';
import { AppState } from 'src/app/store/models/app-state.model';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  side_menus$: Observable<Menu[]> = this.store.select(state => state.menu.side_menus);
  side_menus_loading$: Observable<boolean> = this.store.select(state => state.menu.side_menus_loading);
  side_menus_error$: Observable<Error> = this.store.select(state => state.menu.side_menus_error);

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.getSideMenus();
  }

  getSideMenus() {
    this.store.dispatch(new LoadSideMenusAction());
  }

  closeSideMenu(event) {
    $(".side-menu").removeClass("side-menu-active"), $("#close_side_menu").fadeOut(200), $(".pushwrap").removeClass("active");
  }
}
