<div class="slider-social" *ngIf="!(loading$ | async)">
    <ul class="list-unstyled">

        <li class="animated-wrap" *ngFor="let item of social_media_links$ | async">
            <a class="animated-element" [href]="item.url" [title]="item.description" [target]="item.url_type">
                <i [ngClass]="item.class" aria-hidden="true"></i>
            </a>
        </li>

    </ul>
</div>