<div class="side-footer text-white w-100">
    <ul class="social-icons-simple" *ngIf="!(loading$ | async)">

        <li class="animated-wrap" *ngFor="let item of social_media_links$ | async">
            <a class="animated-element" [href]="item.url" [title]="item.description" [target]="item.url_type">
                <i [ngClass]="item.class" aria-hidden="true"></i>
            </a>
        </li>

    </ul>
    <p class="text-white">&copy; 2020 Duetron Softwares</p>
</div>