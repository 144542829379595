import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafeHtmlPipe } from './pipes/safe.pipe';
import { ChunksPipe } from './pipes/chunks.pipe';
import { APIInterceptor } from './interceptors/api-interceptors';
import { HttpRequestInterceptor } from './interceptors/http-request-interceptor';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/pages/home/home.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { LoaderComponent } from './components/layouts/loader/loader.component';
import { SliderComponent } from './components/layouts/slider/slider.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ReferencesComponent } from './components/pages/references/references.component';
import { NewsComponent } from './components/pages/news/news/news.component';
import { NewsDetailsComponent } from './components/pages/news/news-details/news-details.component';
import { PageNotFoundComponent } from './components/pages/page-not-found/page-not-found.component';
import { ProductDetailsComponent } from './components/pages/products/product-details/product-details.component';
import { PagesComponent } from './components/pages/pages/pages/pages.component';
import { SliderSocialComponent } from './components/layouts/partials/slider-social/slider-social.component';
import { FooterSocialComponent } from './components/layouts/partials/footer-social/footer-social.component';
import { SideMenuComponent } from './components/layouts/partials/side-menu/side-menu.component';
import { SideFooterComponent } from './components/layouts/partials/side-footer/side-footer.component';
import { ContactFormComponent } from './components/layouts/partials/contact-form/contact-form.component';
import { ContactInfoComponent } from './components/layouts/partials/contact-info/contact-info.component';
import { RecentNewsComponent } from './components/layouts/widgets/recent-news/recent-news.component';
import { NewsMenuComponent } from './components/layouts/widgets/news-menu/news-menu.component';
import { NewsDetailsMenuComponent } from './components/layouts/widgets/news-details-menu/news-details-menu.component';
import { PaginationComponent } from './components/pagination/pagination.component';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NewsReducer } from './store/reducers/news.reducer';
import { NewsEffects } from './store/effects/news.effects';
import { ProductReducer } from './store/reducers/product.reducer';
import { ProductEffects } from './store/effects/product.effects';
import { PageReducer } from './store/reducers/page.reducer';
import { PageEffects } from './store/effects/page.effects';
import { SocialMediaReducer } from './store/reducers/social-media.reducer';
import { SocialMediaEffects } from './store/effects/social-media.effects';
import { CompanyReducer } from './store/reducers/company.reducer';
import { CompanyEffects } from './store/effects/company.effects';
import { SliderEffects } from './store/effects/slider.effects';
import { SliderReducer } from './store/reducers/slider.reducer';
import { ReferenceReducer } from './store/reducers/reference.reducer';
import { ReferenceEffects } from './store/effects/reference.effects';
import { MenuReducer } from './store/reducers/menu.reducer';
import { MenuEffects } from './store/effects/menu.effects';
import { HomeAboutUsComponent } from './components/layouts/home-about-us/home-about-us.component';
import { HomeContactUsComponent } from './components/layouts/home-contact-us/home-contact-us.component';
import { HomeServiceComponent } from './components/layouts/home-service/home-service.component';
import { ServiceReducer } from './store/reducers/service.reducer';
import { ServiceEffects } from './store/effects/service.effects';
import { SectionReducer } from './store/reducers/section.reducer';
import { SectionEffects } from './store/effects/section.effects';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    HomeComponent,
    HeaderComponent,
    SliderComponent,
    FooterComponent,
    ContactComponent,
    ReferencesComponent,
    NewsComponent,
    NewsDetailsComponent,
    PageNotFoundComponent,
    ProductDetailsComponent,
    PagesComponent,
    SliderSocialComponent,
    FooterSocialComponent,
    SideMenuComponent,
    SideFooterComponent,
    ContactFormComponent,
    ContactInfoComponent,
    RecentNewsComponent,
    NewsMenuComponent,
    NewsDetailsMenuComponent,
    PaginationComponent,
    SafeHtmlPipe,
    HomeAboutUsComponent,
    HomeContactUsComponent,
    HomeServiceComponent,
    ChunksPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ShareButtonsModule,
    ShareIconsModule,
    StoreModule.forRoot({
      news: NewsReducer,
      product: ProductReducer,
      page: PageReducer,
      socialMedia: SocialMediaReducer,
      company: CompanyReducer,
      slider: SliderReducer,
      reference: ReferenceReducer,
      menu: MenuReducer,
      service: ServiceReducer,
      section: SectionReducer,
    }),
    EffectsModule.forRoot([
      NewsEffects,
      ProductEffects,
      PageEffects,
      SocialMediaEffects,
      CompanyEffects,
      SliderEffects,
      ReferenceEffects,
      MenuEffects,
      ServiceEffects,
      SectionEffects,
    ])
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
