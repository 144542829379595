import { Action } from '@ngrx/store';
import { Service } from 'src/app/models/service';

export enum ServiceActionTypes {
  LOAD_SERVICES= '[SERVICE] Load Services',
  LOAD_SERVICES_SUCCESS = '[SERVICE] Load Services Success',
  LOAD_SERVICES_FAILURE = '[SERVICE] Load Services Failure',
}

export class LoadServicesAction implements Action {
  readonly type = ServiceActionTypes.LOAD_SERVICES;
}

export class LoadServicesSuccessAction implements Action {
  readonly type = ServiceActionTypes.LOAD_SERVICES_SUCCESS;

  constructor(public payload: Array<Service>) {}
}

export class LoadServicesFailureAction implements Action {
  readonly type = ServiceActionTypes.LOAD_SERVICES_FAILURE;

  constructor(public payload: Error) {}
}

export type ServiceAction = LoadServicesAction |
LoadServicesSuccessAction |
LoadServicesFailureAction;
