<ng-container *ngIf="!(news_details_menus_loading$ | async) && (news_details_menus$ | async).length > 0">
    <div class="widget bg-light my-4" *ngFor="let news_details_menu of news_details_menus$ | async">
        <h5 class="mb-4">{{ news_details_menu.name }}</h5>

        <ul class="list-unstyled blog-category m-0 alt-font" *ngIf="news_details_menu.base_items.length > 0">

            <li *ngFor="let item of news_details_menu.base_items">
                <a [routerLink]="[ item.navigation_url ]" [title]="item.title">{{ item.title }}</a>
            </li>

        </ul>
    </div>
</ng-container>