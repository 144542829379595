import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ReferenceService } from 'src/app/services/reference.service';
import {
  LoadReferencesAction,
  LoadReferencesSuccessAction,
  LoadReferencesFailureAction,
  LoadReferenceCategoriesAction,
  LoadReferenceCategoriesSuccessAction,
  LoadReferenceCategoriesFailureAction,
  ReferenceActionTypes
} from '../actions/reference.actions';

@Injectable()
export class ReferenceEffects {

  constructor(
    private actions$: Actions,
    private referenceService: ReferenceService,
  ) { }

  loadReferenceCategories$ = createEffect(() => this.actions$.pipe(
      ofType<LoadReferenceCategoriesAction>(ReferenceActionTypes.LOAD_REFERENCE_CATEGORIES),
      mergeMap(() => this.referenceService.getReferenceCategories()
        .pipe(
          map(referenceCategories => { return new LoadReferenceCategoriesSuccessAction(referenceCategories) }),
          catchError(error => of(new LoadReferenceCategoriesFailureAction(error)))
        )
      )
    )
  );

  loadReferences$ = createEffect(() => this.actions$.pipe(
      ofType<LoadReferencesAction>(ReferenceActionTypes.LOAD_REFERENCES),
      mergeMap(() => this.referenceService.getReferences()
        .pipe(
          map(references => { return new LoadReferencesSuccessAction(references) }),
          catchError(error => of(new LoadReferencesFailureAction(error)))
        )
      )
    )
  );

}
