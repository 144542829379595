import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { News } from 'src/app/models/news';
import { Section } from 'src/app/models/section';
import { SeoService } from 'src/app/services/seo.service';
import { LoadAllNewsAction } from 'src/app/store/actions/news.actions';
import { LoadSectionAction } from 'src/app/store/actions/section.actions';
import { AppState } from 'src/app/store/models/app-state.model';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  section$: Observable<Section> = this.store.select(state => state.section.section);
  section_loadig$: Observable<boolean> = this.store.select(state => state.section.section_loading);
  $section_error$: Observable<Error> = this.store.select(state => state.section.error);

  news$: Observable<News[]> = this.store.select(state => state.news.all_news);
  news_loading$: Observable<boolean> = this.store.select(state => state.news.all_news_loading);
  news_error$: Observable<Error> = this.store.select(state => state.news.all_news_error);

  pageOfItems: Array<any>;

  constructor(
    private seoService: SeoService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.seoService.updateTitle('DUETRON - News');
    this.seoService.updateMetaName('description', 'DUETRON - News');
    this.seoService.updateMetaProperty('og:url', window.location.href);
    this.getSection();
    this.getNews();
  }

  getNews() {
    this.store.dispatch(new LoadAllNewsAction());
  }

  getSection() {
    this.store.dispatch(new LoadSectionAction('news'));
  }

  onChangePage(pageOfItems: Array<any>) {
    //document.querySelector('.news-title').scrollIntoView({ behavior: 'smooth' });
    this.pageOfItems = pageOfItems;
  }
}
