import { Action } from '@ngrx/store';
import { News } from 'src/app/models/news';

export enum NewsActionTypes {
  LOAD_ALL_NEWS = '[NEWS] Load All News',
  LOAD_ALL_NEWS_SUCCESS = '[NEWS] Load All News Success',
  LOAD_ALL_NEWS_FAILURE = '[NEWS] Load All News Failure',

  LOAD_RECENT_NEWS = '[NEWS] Load Recent News',
  LOAD_RECENT_NEWS_SUCCESS = '[NEWS] Load Recent News Success',
  LOAD_RECENT_NEWS_FAILURE = '[NEWS] Load Recent News Failure',

  LOAD_NEWS = '[NEWS] Load News',
  LOAD_NEWS_SUCCESS = '[NEWS] Load News Success',
  LOAD_NEWS_FAILURE = '[NEWS] Load News Failure',

}

export class LoadAllNewsAction implements Action {
  readonly type = NewsActionTypes.LOAD_ALL_NEWS;
}

export class LoadAllNewsSuccessAction implements Action {
  readonly type = NewsActionTypes.LOAD_ALL_NEWS_SUCCESS;

  constructor(public payload: Array<News>) {}
}

export class LoadAllNewsFailureAction implements Action {
  readonly type = NewsActionTypes.LOAD_ALL_NEWS_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadRecentNewsAction implements Action {
  readonly type = NewsActionTypes.LOAD_RECENT_NEWS;
}

export class LoadRecentNewsSuccessAction implements Action {
  readonly type = NewsActionTypes.LOAD_RECENT_NEWS_SUCCESS;

  constructor(public payload: Array<News>) {}
}

export class LoadRecentNewsFailureAction implements Action {
  readonly type = NewsActionTypes.LOAD_RECENT_NEWS_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadNewsAction implements Action {
  readonly type = NewsActionTypes.LOAD_NEWS;

  constructor(public slug: string) {}
}

export class LoadNewsSuccessAction implements Action {
  readonly type = NewsActionTypes.LOAD_NEWS_SUCCESS;

  constructor(public payload: News) {}
}

export class LoadNewsFailureAction implements Action {
  readonly type = NewsActionTypes.LOAD_NEWS_FAILURE;

  constructor(public payload: Error) {}
}

export type NewsAction = LoadAllNewsAction |
LoadAllNewsSuccessAction |
LoadAllNewsFailureAction |
LoadRecentNewsAction |
LoadRecentNewsSuccessAction |
LoadRecentNewsFailureAction |
LoadNewsAction |
LoadNewsSuccessAction |
LoadNewsFailureAction;
