import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { delay } from 'rxjs/operators';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'DUETRON';
  loading: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private router: Router
  ) { }

  ngOnInit() {
    this.listenToLoading();
    this.routerEvent(this.router);
  }

  listenToLoading(): void {
    this.loadingService.loadingSub.pipe(delay(0)).subscribe((loading) => {
      this.loading = loading;
    });
  }

  routerEvent(router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let side_menu = document.querySelector('.side-menu');
        if (side_menu) {
          side_menu.classList.remove('side-menu-active');
        }
        let close_side_menu = document.querySelector('#close_side_menu') as HTMLElement;
        if (close_side_menu) {
          close_side_menu.style.display = 'none';
        }
      }
    })
  }

}
