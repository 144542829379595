import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Slider } from '../models/slider';

@Injectable({
  providedIn: 'root'
})
export class SliderService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getSliders(): Observable<Slider[]> {
    return this.httpClient.get<Slider[]>("sliders");
  }
}
