import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Company } from 'src/app/models/company';
// import { SocialMedia } from 'src/app/models/social-media';
import { LoadCompaniesAction, LoadCompanyAction } from 'src/app/store/actions/company.actions';
import { LoadSocialMediasAction } from 'src/app/store/actions/social-media.actions';
import { AppState } from 'src/app/store/models/app-state.model';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {

  companies$: Observable<Company[]> = this.store.select(state => state.company.companies);
  companies_loading$: Observable<boolean> = this.store.select(state => state.company.companies_loading);
  companies_error$: Observable<Error> = this.store.select(state => state.company.companies_error);

  // company$: Observable<Company> = this.store.select(state => state.company.company);
  // company_loading$: Observable<boolean> = this.store.select(state => state.company.company_loading);
  // company_error$: Observable<Error> = this.store.select(state => state.company.company_error);

  // social_media_links$: Observable<SocialMedia[]> = this.store.select(state => state.socialMedia.social_medias);
  // social_medias_loading$: Observable<boolean> = this.store.select(state => state.socialMedia.social_medias_loading);
  // social_medias_error$: Observable<Error> = this.store.select(state => state.socialMedia.error);

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.getCompanies();
    // this.getCompanyInfo();
    // this.getSocialMediaLinks();
  }

  getCompanies() {
    this.store.dispatch(new LoadCompaniesAction());
  }

  getCompanyInfo() {
    this.store.dispatch(new LoadCompanyAction());
  }

  getSocialMediaLinks() {
    this.store.dispatch(new LoadSocialMediasAction());
  }
}
