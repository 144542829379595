<!-- <section id="blog">
    <div class="container" *ngIf="!(services_loading$ | async)">

        <div class="row">
            <div class="col-md-12 text-center wow fadeIn">
                <div class="title d-inline-block">
                    <h2 class="gradient-text1 mb-3">Our Services</h2>
                    <p>Duetron Software Development Services</p>
                </div>
            </div>
        </div>

        <div class="row mb-3" *ngFor="let chunk of (services$ | async) | chunks: 2">

            <div class="col-lg-6" *ngFor="let item of chunk; let i = index" [ngClass]="{ 'mb-lg-0' : i % 2 === 0 }">
                <div class="price-two" [ngClass]="{ 'hvr-blue' : i % 2 === 0, 'hvr-gradient' : i % 2 === 1 }">
                    <h5 class="currency third-color alt-font d-inline-block font-weight-normal mb-3">{{ item.title }}</h5>
                    <p>{{ item.description }}</p>
                </div>
            </div>

        </div>

    </div>
</section> -->

<section id="about">
    <div class="container" *ngIf="!(services_loading$ | async)">

        <div class="row">
            <div class="col-md-12 text-center wow fadeIn">
                <div class="title d-inline-block">
                    <!-- <h6 class="mb-3">Our Services</h6> -->
                    <h2 class="gradient-text2 mb-3" style="font-size: 2rem;">Smart solutions for extreme possibilities</h2>
                    <p style="font-size: 20px;">We are provide responsive software solutions for different industries almost more than 10 years with keen experience.</p>
                </div>
            </div>
        </div>

        <div class="row justify-content-center mt-lg-1" *ngFor="let chunk of (services$ | async) | chunks: 3">
            <div class="col-lg-4 col-md-12 wow" *ngFor="let item of chunk; let i = index" [ngClass]="{ 'fadeInLeft' : i === 0, 'fadeInUp' : i === 1, 'fadeInRight' : i === 2 }">
                <div class="feature-box text-center">
                    <span *ngIf="item.icon_url !== null" class="item-number gradient-text1">
                      <img [src]="item.icon_url" style="width: 60px;">
                    </span>
                    <h6 class="mb-4 currency" style="color: #6d6d6d;">{{ item.title }}</h6>
                    <p>{{ item.description }}</p>
                </div>
            </div>
        </div>

    </div>
</section>