import { News } from 'src/app/models/news';
import { NewsAction, NewsActionTypes } from '../actions/news.actions';

export interface NewsState {
  all_news: News[],
  all_news_loading: boolean,
  all_news_error: Error,

  recent_news: News[],
  recent_news_loading: boolean,
  recent_news_error: Error,

  news: News,
  news_loading: boolean,
  news_error: Error
};

const initialState: NewsState = {
  all_news: [] = [],
  all_news_loading: false,
  all_news_error: undefined,

  recent_news: [] = [],
  recent_news_loading: false,
  recent_news_error: undefined,

  news: undefined,
  news_loading: false,
  news_error: undefined
};

export function NewsReducer(state: NewsState = initialState, action: NewsAction) {
  switch (action.type) {
    case NewsActionTypes.LOAD_ALL_NEWS:
      return {
        ...state,
        all_news_loading: true
      };
    case NewsActionTypes.LOAD_ALL_NEWS_SUCCESS:
      return {
        ...state,
        all_news: action.payload,
        all_news_loading: false
      };
    case NewsActionTypes.LOAD_ALL_NEWS_FAILURE:
      return {
        ...state,
        all_news_error: action.payload,
        all_news_loading: false
      };
    case NewsActionTypes.LOAD_RECENT_NEWS:
      return {
        ...state,
        recent_news_loading: true
      };
    case NewsActionTypes.LOAD_RECENT_NEWS_SUCCESS:
      return {
        ...state,
        recent_news: action.payload,
        recent_news_loading: false
      };
    case NewsActionTypes.LOAD_RECENT_NEWS_FAILURE:
      return {
        ...state,
        recent_news_error: action.payload,
        recent_news_loading: false
      };
    case NewsActionTypes.LOAD_NEWS:
      return {
        ...state,
        news_loading: true
      };
    case NewsActionTypes.LOAD_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload,
        news_loading: false
      };
    case NewsActionTypes.LOAD_NEWS_FAILURE:
      return {
        ...state,
        news_error: action.payload,
        news_loading: false
      };

    default:
      return state;
  }
}
