import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private seoService: SeoService
  ) { }

  ngOnInit(): void {
    this.seoService.updateTitle('DUETRON - Home');
    this.seoService.updateMetaName('description', 'DUETRON - Duetron, cctv softwares, fire management softwares, cctv radar softwares, access control softwares');
    this.seoService.updateMetaName('keywords', 'duetron, bosch cctv, cctv software, bosch fire, fire software, access control, access control software, cctv radar, cctv radar software');
    this.seoService.updateMetaProperty('og:url', window.location.href);
  }

}
