import { SocialMedia } from 'src/app/models/social-media';
import { SocialMediaAction, SocialMediaActionTypes } from '../actions/social-media.actions';

export interface SocialMediaState {
  social_medias: SocialMedia[],
  social_medias_loading: boolean,
  error: Error
};

const initialState: SocialMediaState = {
  social_medias: [] = [],
  social_medias_loading: false,
  error: undefined
};

export function SocialMediaReducer(state: SocialMediaState = initialState, action: SocialMediaAction) {
  switch (action.type) {
    case SocialMediaActionTypes.LOAD_SOCIAL_MEDIAS:
      return {
        ...state,
        social_medias_loading: true
      };
    case SocialMediaActionTypes.LOAD_SOCIAL_MEDIAS_SUCCESS:
      return {
        ...state,
        social_medias: action.payload,
        social_medias_loading: false
      };
    case SocialMediaActionTypes.LOAD_SOCIAL_MEDIAS_FAILURE:
      return {
        ...state,
        error: action.payload,
        social_medias_loading: false
      };
    default:
      return state;
  }
}
