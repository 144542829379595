<section class="bg-light text-center">
    <h2 class="d-none">hidden</h2>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <app-footer-social></app-footer-social>
                <p class="company-about fadeIn">&copy; 2020 Duetron Softwares</p>
            </div>
        </div>
    </div>
</section>

<a class="scroll-top-arrow" href="javascript:void(0);"><i class="fa fa-angle-up"></i></a>

<div id="aimated-cursor">
    <div id="cursor">
        <div id="cursor-loader"></div>
    </div>
</div>