import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Page } from 'src/app/models/page';
import { LoadPageAction } from 'src/app/store/actions/page.actions';
import { AppState } from 'src/app/store/models/app-state.model';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  page$: Observable<Page> = this.store.select(state => state.page.page);
  loading$: Observable<boolean> = this.store.select(state => state.page.page_loading);
  error$: Observable<Error> = this.store.select(state => state.page.error);

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(routeParams => {
      this.getPageBySlug(routeParams.slug);
    });
  }

  getPageBySlug(slug: string) {
    this.store.dispatch(new LoadPageAction(slug));
  }
}
