declare var $: any;

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Reference } from 'src/app/models/reference';
import { ReferenceCategory } from 'src/app/models/reference-category';
import { Section } from 'src/app/models/section';
import { SeoService } from 'src/app/services/seo.service';

import {
  LoadReferenceCategoriesAction,
  LoadReferencesAction,
} from 'src/app/store/actions/reference.actions';
import { LoadSectionAction } from 'src/app/store/actions/section.actions';
import { AppState } from 'src/app/store/models/app-state.model';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss'],
})
export class ReferencesComponent implements OnInit, OnDestroy {

  section$: Observable<Section> = this.store.select(state => state.section.section);
  section_loadig$: Observable<boolean> = this.store.select(state => state.section.section_loading);
  $section_error$: Observable<Error> = this.store.select(state => state.section.error);

  reference_categories$: Observable<ReferenceCategory[]> = this.store.select(state => state.reference.reference_categories);
  reference_categories_loading$: Observable<boolean> = this.store.select(state => state.reference.reference_categories_loading);
  reference_categories_error$: Observable<Error> = this.store.select(state => state.reference.reference_categories_error);

  references$: Observable<Reference[]> = this.store.select(state => state.reference.references);
  references_loading$: Observable<boolean> = this.store.select(state => state.reference.references_loading);
  references_error$: Observable<Error> = this.store.select(state => state.reference.references_error);

  constructor(
    private seoService: SeoService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.seoService.updateTitle('DUETRON - References');
    this.seoService.updateMetaName('description', 'DUETRON - References');
    this.seoService.updateMetaProperty('og:url', window.location.href);
    this.getSection();
    this.getReferenceCategories();
    this.getReferences();
  }

  getSection() {
    this.store.dispatch(new LoadSectionAction('references'));
  }

  getReferenceCategories() {
    this.store.dispatch(new LoadReferenceCategoriesAction());
  }

  getReferences() {
    this.store.dispatch(new LoadReferencesAction());

    this.references$.subscribe((data) => {
      if (data.length > 0) {
        setTimeout(() => {
          this.updateScript();
        }, 2000);
      }
    });
  }

  updateScript() {

    if (!Boolean($('#js-grid-mosaic-flat').data('cubeportfolio'))) {
        $('#js-grid-mosaic-flat').cubeportfolio({
          filters: '#js-filters-mosaic-flat',
          layoutMode: 'grid',
          sortByDimension: false,
          mediaQueries: [
            {
              width: 1500,
              cols: 4,
            },
            {
              width: 1100,
              cols: 3,
            },
            {
              width: 800,
              cols: 2,
            },
            {
              width: 480,
              cols: 2,
              options: {
                gapHorizontal: 15,
                gapVertical: 15,
              },
            },
          ],
          defaultFilter: '*',
          animationType: 'fadeOutTop',
          // gapHorizontal: 0,
          // gapVertical: 0,
          gridAdjustment: 'responsive',
          caption: 'zoom',
          lightboxDelegate: '.cbp-lightbox',
          lightboxGallery: true,
          lightboxTitleSrc: 'data-title',

          // plugins: {
          //     loadMore: {
          //         element: '#js-loadMore-mosaic-flat',
          //         action: 'click',
          //         loadItems: 3,
          //     }
          // },
        });
    }
  }

  ngOnDestroy(): void {
    // if (Boolean($('#js-grid-mosaic-flat').data('cubeportfolio'))) {
    //   $('#js-grid-mosaic-flat').cubeportfolio('destroy');
    // }
  }
}
