import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Section } from 'src/app/models/section';
import { SeoService } from 'src/app/services/seo.service';
import { LoadSectionAction } from 'src/app/store/actions/section.actions';
import { AppState } from 'src/app/store/models/app-state.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  section$: Observable<Section> = this.store.select(state => state.section.section);
  section_loadig$: Observable<boolean> = this.store.select(state => state.section.section_loading);
  $section_error$: Observable<Error> = this.store.select(state => state.section.error);

  constructor(
    private seoService: SeoService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.seoService.updateTitle('DUETRON - Contact Us');
    this.seoService.updateMetaName('description', 'DUETRON - Contact Us');
    this.seoService.updateMetaProperty('og:url', window.location.href);
    this.getSection();
  }

  getSection() {
    this.store.dispatch(new LoadSectionAction('contact-us'));
  }
}
