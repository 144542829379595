import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Reference } from '../models/reference';
import { ReferenceCategory } from '../models/reference-category';

@Injectable({
  providedIn: 'root'
})
export class ReferenceService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getReferenceCategories(): Observable<ReferenceCategory[]> {
    return this.httpClient.get<ReferenceCategory[]>("referencecategories");
  }

  getReferences(): Observable<Reference[]> {
    return this.httpClient.get<Reference[]>("references");
  }
}
