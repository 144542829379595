<!-- <section id="home" class="p-0">
  <h2 class="d-none">hidden</h2>
  <div class="fullscreen parallax bg-img-count2" style="background-image: url(assets/images/countdown_bg2.jpg&quot;); background-size: cover; background-repeat: no-repeat; background-attachment: fixed; background-position: center 0px;">
      <div class="bg-overlay bg-black opacity-6"></div>
      <div class="col-xs-12 text-center center-col">

          <div class="title d-inline-block mb-0">
              <h2 class="mb-3 text-white font-weight-bold">WE'RE <span class="third-color">COMING SOON</span></h2>
              <p class="text-white">Under Construction</p>
          </div>

      </div>
  </div>

</section>
 -->
<section class="pb-0 bg-img" [ngStyle]="{ 'background-image' : 'url(' + (section$ | async)?.background_image_url + ')' }">
    <div class="bg-overlay gradient-duetron-bg opacity-8"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="cover-text mt-5">
                    <h2 class="text-white mb-2">References</h2>
                    <div class="page_nav">
                        <a routerLink="/" title="Home">Home </a>
                        <span class="third-color"> <i class="fa fa-angle-double-right"></i> References</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="portfolio" class="cube-portfolio1">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 wow fadeInLeft">
                <div class="title">
                    <h3 class="gradient-text1">References</h3>
                </div>
            </div>
        </div>

        <div class="row wow fadeInUp">
            <div class="col-md-12">

                <div id="js-filters-mosaic-flat" class="cbp-l-filters-button" *ngIf="!(reference_categories_loading$ | async)">

                    <div data-filter="*" class="cbp-filter-item-active cbp-filter-item">All</div>
                    <span class="text-blue">/</span>

                    <ng-container *ngFor="let item of reference_categories$ | async; let i = index">
                        <div [attr.data-filter]="'.' + item.slug" class="cbp-filter-item">{{ item.title }}</div>
                        <span class="text-blue" *ngIf="(reference_categories$ | async).length !== (i + 1)"> / </span>
                    </ng-container>

                </div>

                <ng-container *ngIf="!(references_loading$ | async)">
                    <div id="js-grid-mosaic-flat" class="cbp cbp-l-grid-mosaic-flat">

                        <div class="cbp-item" [ngClass]="item.reference_category.slug" *ngFor="let item of references$ | async; let i=index">
                            <a [href]="item.image_url" [title]="item.title" class="cbp-caption cbp-lightbox" [attr.data-title]="item.title">
                                <div class="cbp-caption-defaultWrap">
                                    <img [src]="item?.image_url" [alt]="item.title">
                                </div>
                                <div class="cbp-caption-activeWrap"></div>
                                <div class="cbp-l-caption-alignCenter center-block">
                                    <div class="cbp-l-caption-body">
                                        <div class="plus"></div>
                                        <h5 class="text-white mb-1">{{ item.title }}</h5>
                                        <p class="text-white">{{ item.workspace_category.title }}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>
                </ng-container>

            </div>
        </div>

    </div>
</section>
