<header class="cursor-light">

    <!--Navigation-->
    <nav class="navbar navbar-top-default navbar-expand-lg navbar-gradient nav-line">
        <!-- <nav class="navbar navbar-top-default navbar-expand-lg navbar-simple nav-line"> -->
        <div class="container">

            <a routerLink="/" class="logo" title="DUETRON - Home">
                <!-- <img src="assets/images/logo-white.png" alt="logo" class="logo-dark default"> -->
                <img src="assets/logo/duetron-logo-white2.png" alt="duetron logo" class="logo-dark default">
            </a>

            <!--Nav Links-->
            <div class="collapse navbar-collapse" id="wexim">
                <div class="navbar-nav ml-auto" *ngIf="!(header_menus_loading$ | async)">

                    <ng-container *ngFor="let header_menu of header_menus$ | async">
                        <a class="nav-link link" [routerLink]="header_menu.navigation_url" [title]="header_menu.title" routerLinkActive="active">
                          {{ header_menu.title }}
                        </a>
                    </ng-container>

                    <span class="menu-line"><i class="fa fa-angle-down" aria-hidden="true"></i></span>
                </div>
            </div>

            <!--Side Menu Button-->
            <a class="d-inline-block parallax-btn sidemenu_btn" id="sidemenu_toggle" (click)="openSideMenu($event)">
                <div class="animated-wrap sidemenu_btn_inner">
                    <div class="animated-element">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </a>

            <!-- <a href="javascript:void(0)" class="d-inline-block parallax-btn sidemenu_btn" id="sidemenu_toggle" (click)="openSideMenu($event)">
                <div class="animated-wrap sidemenu_btn_inner">
                    <div class="animated-element">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </a> -->

        </div>
    </nav>

    <app-side-menu></app-side-menu>

</header>