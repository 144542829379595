<div class="footer-social" *ngIf="!(loading$ | async)">
    <ul class="list-unstyled">
        <li *ngFor="let item of social_media_links$ | async; let i = index">
            <a class="wow" [href]="item.url" [target]="item.url_type" [title]="item.description" [ngClass]="{ 'fadeInUp' : i % 2 === 0, 'fadeInDown' : i % 2 === 1 }">
                <i [ngClass]="item.class" aria-hidden="true"></i>
            </a>
        </li>
        <li>
            <a class="wow" [ngClass]="{ 'fadeInUp' : (social_media_links$ | async).length % 2 === 0, 'fadeInDown' : (social_media_links$ | async).length % 2 === 1 }" href="mailto:info@duetron.com">
                <i class="fa fa-envelope-o" aria-hidden="true"></i>
            </a>
        </li>
    </ul>
</div>