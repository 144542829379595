import { Reference } from 'src/app/models/reference';
import { ReferenceCategory } from 'src/app/models/reference-category';
import { ReferenceAction, ReferenceActionTypes } from '../actions/reference.actions';

export interface ReferenceState {
  references: Reference[],
  references_loading: boolean,
  references_error: Error,
  reference_categories: ReferenceCategory[],
  reference_categories_loading: boolean,
  reference_categories_error: Error,
};

const initialState: ReferenceState = {
  references: [] = [],
  references_loading: false,
  references_error: undefined,
  reference_categories: [] = [],
  reference_categories_loading: false,
  reference_categories_error: undefined
};

export function ReferenceReducer(state: ReferenceState = initialState, action: ReferenceAction) {
  switch (action.type) {
    case ReferenceActionTypes.LOAD_REFERENCE_CATEGORIES:
      return {
        ...state,
        reference_categories_loading: true
      };
    case ReferenceActionTypes.LOAD_REFERENCE_CATEGORIES_SUCCESS:
      return {
        ...state,
        reference_categories: action.payload,
        reference_categories_loading: false
      };
    case ReferenceActionTypes.LOAD_REFERENCE_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        reference_categories_loading: false
      };
    case ReferenceActionTypes.LOAD_REFERENCES:
      return {
        ...state,
        references_loading: true
      };
    case ReferenceActionTypes.LOAD_REFERENCES_SUCCESS:
      return {
        ...state,
        references: action.payload,
        references_loading: false
      };
    case ReferenceActionTypes.LOAD_REFERENCES_FAILURE:
      return {
        ...state,
        error: action.payload,
        references_loading: false
      };
    default:
      return state;
  }
}
