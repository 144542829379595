import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BaseMenu } from 'src/app/models/base-menu';
import { LoadNewsDetailsMenusAction } from 'src/app/store/actions/menu.actions';
import { AppState } from 'src/app/store/models/app-state.model';

@Component({
  selector: 'app-news-details-menu',
  templateUrl: './news-details-menu.component.html',
  styleUrls: ['./news-details-menu.component.scss']
})
export class NewsDetailsMenuComponent implements OnInit {

  news_details_menus$: Observable<BaseMenu[]> = this.store.select(state => state.menu.news_details_menus);
  news_details_menus_loading$: Observable<boolean> = this.store.select(state => state.menu.news_details_menus_loading);
  news_details_menus_error$: Observable<Error> = this.store.select(state => state.menu.news_details_menus_error);

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.getNewsDetailsMenu();
  }

  getNewsDetailsMenu() {
    this.store.dispatch(new LoadNewsDetailsMenusAction());
  }
}
