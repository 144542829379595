import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactForm } from 'src/app/models/contact-form';
import { AlertService } from 'src/app/services/alert.service';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  contactForm: ContactForm;
  sending = false;

  contactFormGroup: FormGroup;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

  constructor(
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.createContactForm();
  }

  createContactForm() {
    this.contactFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
      subject: ['', Validators.required],
      phone: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  sendMessage() {
    if (this.contactFormGroup.valid) {
      this.sending = true;

      this.contactService
        .sendMessage(this.contactFormGroup.value)
        .subscribe((response) => {
          if (response.success === true) {
            this.alertService.success(response.message);
            this.contactFormGroup.reset();
          } else {
            this.alertService.error(response.message);
          }
          this.sending = false;
        });
    }
  }

}
